import React from "react";

const TableUi = () => {
  return (
    <div className="shimmer-wrapper-table">
      <table className="skeleton-table">
        <thead>
          <tr>
            {[...Array(9)]?.map((_, index) => {
              return (
                <th key={index} style={{ padding: "0", margin: "0" }}>
                  <div className="shimmer_table skeleton-cell"></div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {[...Array(25)].map((_, index) => (
            <tr key={index}>
              {[...Array(9)].map((_, index) => (
                <td key={index} style={{ padding: "0", margin: "0" }}>
                  <div className="shimmer_table skeleton-cell"></div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableUi;
