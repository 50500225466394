import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useModel } from "../../hooks/useModel/useModel";
import { useCustomer } from "../../context/CustomerProvider";
import account_icons from "./../../assets/images/account_icons.svg";
import search_icons from "../../assets/images/searchIcons.svg";
import filterIcons from "../../assets/images/Filter list alt.svg";

import EmployeeList from "../../skeleton/employeeDetails/EmployeeList";
import { MdCheckBox } from "react-icons/md";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import { useEmployee } from "../../context/EmpProvider";

import Loader from "../../assets/gif/Loader.gif";
import Confirm from "../../model/confirm/Confirm";


const Nominate = ({
  filteredEmployees,
  setFilteredEmployees,
  employee,
  displayedEmployees,
  loading,
  handleScroll,
  searchTerm,
  handleSearchChange,
  openFilter,
  popupFilterBoxRef,
  handleClearAll,
  handleSelect,
  select,
  setCurrent,
  filterConditinally,
  handleSelectCheckBox,
  current,
  applyFilter,
  filterLoading,
  setOpenFilter,
  setRightSidebar,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const naviagte = useNavigate();
  const { open, handleClose, handleOpen } = useModel();

  const { customer ,setCustomer} = useCustomer();
  const [selectOne, setSelectOne] = useState(null);
 

  const [info,setInfo]=useState({
    id:"",
    name:null,
  })
  const [loadingHr, setLoadingHr] = useState(false);
  const [prevHr, setPrevHr] = useState("");

  const { setEmployee } = useEmployee();
  useEffect(() => {
    setTimeout(() => {
      setIsOpen(true);
    });
  }, []);

  const message = {
    title: "Change HR Confirmation?",
    content:info?.name?
    info?.name===prevHr?`Your HR remains the same: ${prevHr}.`:
    prevHr===""?
    `You are nominating ${info?.name} as the new HR.`

    :
    `Your previous HR was ${prevHr}, and your new HR will be ${info?.name}. Are you sure you want to change your HR?`
    :`Your HR remains the same: ${prevHr}.`,
    leftBtn:!customer?.data?.isHr?.isHrAssign?"Okay":
    "Change HR",

    rightBtn: "Cancel",
    type: "WarningChangeHr",
  };


  useEffect(() => {
    if (customer && customer?.data && customer?.data?.isHr?.isHrAssign) {
      const hr=employee?.find((h,i)=>h?.isHr)
      console.log(hr?.firstName)
      setPrevHr(`${hr?.firstName} ${hr?.lastName}`);
    }
  }, [customer,employee]);


  useEffect(()=>{
if(customer && customer?.data && customer?.data?.isHr?.isHrAssign){
  const empInfo=employee?.find((hr)=>hr?.isHr);
  if(empInfo){
    setSelectOne(empInfo.employeeId);

  }
  // console.log("check",customer?.data?.isHr?.employeeId)
}

},[customer]);


const handleOverlayClick = (e) => {
  // Check if the clicked element is the overlay (not the modal)
  if (e.target.classList.contains("modal-overlayHr")) {
    if(customer && customer?.data && customer?.data?.isHr?.isHrAssign){
      setRightSidebar(false)
    }
  }
};




  return (
    <div className={`nominateHr modal-overlayHr ${isOpen ? "open" : ""}`}
    onClick={handleOverlayClick}
    >
      <div className="nominateHrModel">
        <div className="navHr">
          <div
            className="menu_nav subMenu_nav"
            onClick={() => naviagte("/admin-account")}
          >
            <div className="one">
              <span>
                <b>
                  {customer?.data?.businessname
                    ?.split(" ")
                    .slice(0, 2)
                    .join(" ")}
                  {customer?.data?.businessname?.split(" ").length > 2 && "..."}{" "}
                </b>
              </span>
            </div>
            <div className="two">
              <img
                src={customer?.data?.businesslogo}
                alt="user"
                className="logoCompanyNavbar"
              />
            </div>
          </div>
          <div className="menu_nav">
            <img src={account_icons} alt="account" onClick={handleOpen} />
          </div>
        </div>
        <div className="lowerHrSection">
          {(customer?.data?.isHr?.isHrAssign || selectOne) && (
            <div className="parentBtnHr">
              <button className="nominateHrBySelect" onClick={handleOpen}>
                <span>{loadingHr ? "Loading.." : " Nominate Hr"}</span>
              </button>
            </div>
          )}


          <div className="commonBorderForAllHr">
            {filteredEmployees.length > 0 || employee.length > 0 ? (
              <div className="listOfEmployeeRightHr">
                <div className="All_Employees_List">
                  {(filteredEmployees.length > 0 || employee.length > 0) && (
                    <div
                      className="EmployeeSearch"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <h3 className="totalEmployeeCountOfHrSection">
                        Select an employee as HR 
                      </h3>
                      <form
                        className="SearchForm"
                        onSubmit={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <div className="Search_Input_Data">
                          <div
                            className="searchBox"
                            style={{
                              width: "100%",
                            }}
                          >
                            <input
                              type="text"
                              placeholder="Enter Employee Name"
                              value={searchTerm}
                              onChange={handleSearchChange}
                              style={{
                                "--search-icon": `url(${search_icons})`,
                                width: "100%",
                              }}
                            />
                          </div>
                          <div className="Input_Data_Button">
                            {openFilter && (
                              <div
                                className="model_filter"
                                ref={popupFilterBoxRef}
                              >
                                <div className="heading">
                                  <h3 style={{ cursor: "pointer" }}>
                                    Select List
                                  </h3>
                                  <h3
                                    onClick={handleClearAll}
                                    style={{ cursor: "pointer" }}
                                  >
                                    Clear All
                                  </h3>
                                </div>
                                <div className="filterBasedOn">
                                  <div className="name">
                                    {[
                                      "Department",
                                      "Designation",
                                      "Employmen..",
                                      "Work Location",
                                    ].map((based, i) => (
                                      <div
                                        className={`nameOfThat ${
                                          i === select?.index && "skyBlue"
                                        }`}
                                        key={i}
                                        onClick={() => {
                                          handleSelect(i);
                                          setCurrent(based);
                                        }}
                                      >
                                        <p>{based}</p>
                                      </div>
                                    ))}
                                  </div>
                                  <div className="borderCenter"></div>
                                  <div className="nameOfChoosen">
                                    {filterConditinally?.map((v, i) => (
                                      <div
                                        className="checkbox_container"
                                        key={i}
                                      >
                                        <div
                                          className="iconsCheckBox"
                                          onClick={() =>
                                            handleSelectCheckBox(v)
                                          }
                                        >
                                          {v?.isTick ? (
                                            <MdCheckBox fontSize={22} />
                                          ) : (
                                            <MdCheckBoxOutlineBlank
                                              fontSize={22}
                                            />
                                          )}
                                        </div>

                                        <div className="nameDesig">
                                          {current === "Department" && (
                                            <p>{v?.department}</p>
                                          )}
                                          {current === "Designation" && (
                                            <p>{v?.designation}</p>
                                          )}
                                          {current === "Employmen.." && (
                                            <p>{v?.employmenttype}</p>
                                          )}

                                          {current === "Work Location" && (
                                            <p>{v?.city}</p>
                                          )}
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                                <div className="filterBtn">
                                  <button onClick={applyFilter}>
                                    {filterLoading ? (
                                      <span>Filtering</span>
                                    ) : (
                                      <span>Apply Filter</span>
                                    )}
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div
                          className="Search_Filter_Data"
                          onClick={() => setOpenFilter((prev) => !prev)}
                        >
                          <img src={filterIcons} alt="Filter" />
                        </div>
                      </form>
                    </div>
                  )}

                  <div className="Employees_ListsHr" onScroll={handleScroll}>
                    {displayedEmployees.length > 0 ? (
                      displayedEmployees.map((data, i) => (
                        <div
                          key={i}
                          className="Employee_List_Data"
                          //   onClick={() => onEmployeeSelect(data._id)}
                          onClick={() => {
                            setSelectOne(data?.employeeId);
                            setInfo({
                              id:data?.employeeId,
                              name:`${data?.firstName} ${data?.lastName}`
                            })
                          
                          }}
                          tabIndex="0"
                          style={{ padding: "10px" }}
                        >
                          <div className="Employee_Picture">
                            <img
                              src={data?.profilePhoto}
                              alt={`${data?.firstName} ${data?.lastName}'s picture`}
                            />
                          </div>
                          <div className="Employee_Content">
                            <h1 style={{ margin: "0 0 7px 0" }}>
                              {data?.firstName} {data?.lastName}
                            </h1>
                            <p style={{ margin: "0 0 7px 0" }}>
                              <strong style={{ fontWeight: "bold" }}>
                                {data?.designation}
                              </strong>
                            </p>
                            <p style={{ margin: "0 0 7px 0" }}>
                              {data?.emailOfficial}
                            </p>
                            <p style={{ margin: "0" }}>{data?.mobileNo}</p>
                          </div>
                          <div className="status_employee">
                            {selectOne === data?.employeeId ? (
                              <MdCheckBox fontSize={25} />
                            ) : (
                              <MdCheckBoxOutlineBlank fontSize={25} />
                            )}
                          </div>
                        </div>
                      ))
                    ) : (
                      <div style={{ padding: "10px", textAlign: "center" }}>
                        No employee found
                      </div>
                    )}
                    {loading && (
                      <div style={{ padding: "10px", textAlign: "center" }}>
                        <img src={Loader} alt="loader" height={50} width={50} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <EmployeeList />
            )}
          </div>
        </div>
      </div>
      <Confirm open={open} handleClose={handleClose} message={message} info={info}
      
      setRightSidebar={setRightSidebar}

      filteredEmployees={filteredEmployees}
  setFilteredEmployees={setFilteredEmployees}
      />
    </div>
  );
};

export default Nominate;
