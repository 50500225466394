import React, { useContext, useEffect, useState } from 'react'
import {notifications} from "./myProvider"

export const useNotifications=()=>{
    return useContext(notifications);
}
const NotificationsProvider = ({children}) => {
    const [notification,setNotification]=useState([]);
    const [socket, setSocket] = useState(null);
    const [unreadLength, setUnreadLength] = useState(null);
    const [loading, setLoading] = useState(true); 
    const [error, setError] = useState(null); 
  return (
    <notifications.Provider
    value={{
        notification,
        setNotification,
        socket,
        setSocket,
        unreadLength,
        setUnreadLength,
        loading, setLoading,
        error, setError
    }}
    
    >{children}</notifications.Provider>
  )
}

export default NotificationsProvider