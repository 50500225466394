import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/Layout/AdminLayout/Layout";
import { useCustomer } from "../../context/CustomerProvider";
import { useModel } from "../../hooks/useModel/useModel";
import Confirm from "../../model/confirm/Confirm";
const message = {
  title: "Are you sure you want to delete today's records?",
  content:
    "This will permanently delete today's live tracker records. This cannot be undone.",
  leftBtn: "Delete",
  rightBtn: "Cancel",
  type: "Warning",
};
const AdminAccount = () => {
  const { open, handleClose, handleOpen } = useModel();
  const { customer } = useCustomer();
  const [userData, setUserData] = useState({
    email: "",
    password: "",
    customerid: "",
    otp: "",
    firstname: "",
    lastname: "",
    accountcreatedon: "",
    address: "",
    businessname: "",
    city: "",
    country: "",
    licenseconsumed: "",
    licenseexpireon: "",
    licenseremaining: "",
    licensevalidity: "",
    phonenumber: "",
    pincode: "",
    state: "",
    totallicense: "",
    mode: "Trial",
  });

  useEffect(() => {
    setUserData((prevData) => ({
      ...prevData,
      ...customer?.data,
    }));
  }, [customer]);

  return (
    <Layout>
      <div
        className="commonBorderForAll"
        style={{ marginTop: "40px", height: "90%" }}
      >
        <div className="Main_Dashboard_Background">
          <div className="Dashboard_Section">
            <div className="Account_Details">
              <div
                className="Admin_Account"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <h1>Admin Account</h1>
                {userData.customerid === "OKFS2" && (
                  <h1
                    style={{ cursor: "pointer", color: "red" }}
                    onClick={handleOpen}
                  >
                    Delete All Records
                  </h1>
                )}
              </div>
              <div className="Admin_Details">
                {[
                  { label: "Customer ID", value: userData.customerid },
                  { label: "First Name", value: userData.firstname },
                  { label: "Last Name", value: userData.lastname },
                  { label: "Email ID", value: userData.email },
                  { label: "Business Name", value: userData.businessname },
                  { label: "Address", value: userData.address },
                  { label: "Country", value: userData.country },
                  { label: "State", value: userData.state },
                  { label: "City", value: userData.city },
                  { label: "Pincode", value: userData.pincode },
                  { label: "Phone Number", value: userData.phonenumber },
                  {
                    label: "Account Created",
                    value: new Date(userData.accountcreatedon).toLocaleString(),
                  },
                  {
                    label: "Licence Expire On",
                    value: new Date(userData.licenseexpireon).toLocaleString(),
                  },
                  { label: "Total License", value: userData.totallicense },
                  {
                    label: "License Consumed",
                    value: userData.licenseconsumed,
                  },
                  {
                    label: "License Remaining",
                    value: userData.licenseremaining,
                  },
                  {
                    label: "Licence Validity",
                    value: `${userData.mode} ends in ${userData?.licensevalidity} days`,
                    style: { color: "red" },
                  },
                ].map((item, index) => (
                  <div className="Admin_Field" key={index}>
                    <label>{item.label}</label>
                    <label>:</label>
                    <label style={item.style || {}}>{item.value}</label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Confirm open={open} handleClose={handleClose} message={message} />
    </Layout>
  );
};

export default AdminAccount;
