import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import axios from "axios";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import watermark from "../../assets/images/watermarkbw.png";
import logo from "../../assets/images/Okulr_LogoPdf.png";
import Loader from "../../assets/gif/Loader.gif";
import { useCustomer } from "../../context/CustomerProvider";

import TimeFormatter from "../../utils/gloablDateTimeFunction";
import { useAttendanceTable } from "../../hooks/useTime/attendanceTable";

const LogDetails = ({ open, handleClose, employeeId, employeeName }) => {
  const [logData, setLogData] = useState([]);
  const { customer } = useCustomer();
  const [companyLogo, setComplanyLogo] = useState("");
  const [shiftSet, setShiftSet] = useState("");
  const { getTimeByCountry } = useAttendanceTable();
  useEffect(() => {
    // console.log("customer?.data?.businesslogo",customer?.data?.businesslogo)
    setComplanyLogo(customer?.data?.businesslogo);
  }, [customer]);
  const [recordsFound, setRecordsFound] = useState(true);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (open && employeeId) {
      setLoading(true);
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/liveTracker/filterByEmployeeId?employeeId=${employeeId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          if (response.data.message === "Records retrieved successfully") {
            setLoading(false);

            setLogData(response.data.data);
            setShiftSet(response?.data?.shift);
            setRecordsFound(response.data.data.length > 0);
          } else {
            setLoading(false);
            setLogData([]);
            setRecordsFound(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error("There was an error fetching the log details!", error);
          setLogData([]);
          setRecordsFound(false);
        });
    }
  }, [open, employeeId]);

  const tableHeading = [
    { id: 1, name: "S.NO" },
    { id: 2, name: "Date" },
    { id: 3, name: "Employee ID" },
    { id: 4, name: "Employee Name" },
    { id: 5, name: "Shift Timing" },
    { id: 6, name: "In-Timing" },
    { id: 7, name: "Snippet" },
    { id: 8, name: "Out-Timing" },
    { id: 9, name: "Snippet" },
  ];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? "Invalid Date" : date.toLocaleDateString();
  };

  const formatTime = (timeString) => {
    const date = new Date(timeString);
    return isNaN(date.getTime()) ? "N/A" : date.toLocaleTimeString();
  };

  const formatSnippet = (snippet) => {
    return snippet ? `data:image/jpeg;base64,${snippet}` : "";
  };

  const handleLogDownload = async () => {
    try {
      const dataForSummeryApi = logData;

      const doc3 = new jsPDF({
        orientation: "landscape", // Set landscape orientation for more width
        unit: "mm",
        format: [297, 210], // Custom size, 297mm width and 210mm height (A4 landscape)
      });

      if (companyLogo) {
        const logoImage = new Image();
        logoImage.src = companyLogo; // Assuming companyLogo is a base64 encoded image

        // Use a Promise to handle image loading asynchronously
        const loadLogoImage = new Promise((resolve, reject) => {
          logoImage.onload = () => resolve(logoImage);
          logoImage.onerror = () =>
            reject(
              new Error("Failed to load the logo image from the base64 string.")
            );
        });

        const logoImageLoaded = await loadLogoImage;

        const originalWidth = logoImageLoaded.width;
        const originalHeight = logoImageLoaded.height;

        // Set maximum dimensions
        const maxWidth = 70; // Adjust maximum width
        const maxHeight = 35; // Adjust maximum height

        let logoWidth = originalWidth;
        let logoHeight = originalHeight;

        // Calculate aspect ratio and adjust width/height to fit within max dimensions
        const aspectRatio = originalWidth / originalHeight;

        if (logoWidth > maxWidth) {
          logoWidth = maxWidth;
          logoHeight = maxWidth / aspectRatio;
        }

        if (logoHeight > maxHeight) {
          logoHeight = maxHeight;
          logoWidth = maxHeight * aspectRatio;
        }

        // Add the logo to the PDF with the adjusted size
        doc3.addImage(companyLogo, "JPEG", 10, 10, logoWidth, logoHeight);
      }

      // Process table data and add to PDF
      const tableColumn3 = [
        "S.No.",
        "Date",
        "Employee ID",
        "Employee Name",
        "Shift Timing",
        "In-Timing",
        "InSnippet",
        "Out-Timing",
        "Out-Snippet",
      ];

      const tableRows3 = await Promise.all(
        dataForSummeryApi.map(async (member, index) => {
          const imageUrl1 = member.intimeOutTime[0]?.inTimeSnippet
            ? `data:image/png;base64,${member.intimeOutTime[0]?.inTimeSnippet}`
            : null;

          const imageUrl2 = member.intimeOutTime[0]?.outTimeSnippet
            ? `data:image/png;base64,${member.intimeOutTime[0]?.outTimeSnippet}`
            : null;

          return [
            index + 1,
            formatDate(member.inoutDate) || "--",
            member.employeeId || "--",
            member.employeeName || "--",
            shiftSet || "--",

            // formatTime(member.intimeOutTime[0]?.inTime) || "--",
            customer?.data?.country && member.intimeOutTime[0]?.inTime
              ? getTimeByCountry(
                  customer?.data?.country,
                  member.intimeOutTime[0]?.inTime,
                  customer?.data?.state
                )?.newTime
              : "NA" || "--",

            { imageUrl: imageUrl1 },
            // member.intimeOutTime[0]?.outTime
            //   ? formatTime(member.intimeOutTime[0]?.outTime)
            //   : "N/A" || "--",
            customer?.data?.country && member.intimeOutTime[0]?.outTime
              ? getTimeByCountry(
                  customer?.data?.country,
                  member.intimeOutTime[0]?.outTime,
                  customer?.data?.state
                )?.newTime
              : "NA" || "--",

            { imageUrl: imageUrl2 },
          ];
        })
      );

      const pageWidth = doc3.internal.pageSize.width;
      const totalTableWidth = 15 + 30 + 30 + 35 + 35 + 20 + 35 + 30 + 25; // Sum of all the column widths
      const leftMargin = (pageWidth - totalTableWidth) / 2;
      const title = `Attendance Log of ${employeeName}`; // Define the title
      const textWidth = doc3.getTextWidth(title); // Get the width of the text
      const textX = (pageWidth - textWidth) / 2; // Calculate the X position for centered text

      doc3.text(title, textX, 40);

      autoTable(doc3, {
        head: [tableColumn3],
        body: tableRows3,
        startY: 50,
        margin: { top: 50, left: 10, right: 10 },
        styles: { overflow: "linebreak", cellWidth: "wrap" },
        bodyStyles: { cellPadding: { top: 8, bottom: 8, left: 2 } },
        headStyles: { cellPadding: { top: 8, bottom: 8, left: 2 } },
        columnStyles: {
          0: { cellWidth: 15 },
          1: { cellWidth: 30 },
          2: { cellWidth: 35 },
          3: { cellWidth: 35 },
          4: { cellWidth: 35 },
          5: { cellWidth: 20 },
          6: { cellWidth: 35 },
        },
        didDrawCell: (data) => {
          if (data.column.index === 6 || data.column.index === 8) {
            const imageUrl = data.cell.raw.imageUrl;
            if (imageUrl) {
              doc3.addImage(
                imageUrl,
                "JPEG",
                data.cell.x + 2,
                data.cell.y + 2,
                24,
                20
              );
            }
          }
        },
        horizontalPageBreakRepeat: true,
      });

      doc3.save(`${employeeName}.pdf`);
    } catch (error) {
      console.log("Error generating PDF", error);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box className="attendanceTable attendanceTableLog">
        {loading ? (
          <div className="logDetailsLoader">
            <img src={Loader} alt="Loading" />
          </div>
        ) : (
          <>
            <Box className="list ListlogDetails">
              {recordsFound ? (
                <>
                  <table>
                    <thead>
                      <tr className="tableAttendanceHeading">
                        {tableHeading.map((h) => (
                          <th key={h.id}>{h.name}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {logData.map((data, index) => (
                        <tr key={index} className="tableDataName">
                          <td>{index + 1}</td>
                          <td>{formatDate(data.inoutDate)}</td>
                          <td>{data.employeeId}</td>
                          <td>{data.employeeName}</td>
                          <td>{shiftSet}</td>
                          <td>
                            {/* {formatTime(data.intimeOutTime[0]?.inTime)} */}
                            {customer.data.country &&
                            data.intimeOutTime[0]?.inTime ? (
                              <TimeFormatter
                                state={customer.data.state}
                                country={customer.data.country}
                                time={data.intimeOutTime[0]?.inTime}
                              />
                            ) : (
                              "NA"
                            )}
                          </td>
                          <td>
                            {data.intimeOutTime[0]?.inTimeSnippet ? (
                              <img
                                style={{ height: "40px", width: "40px" }}
                                src={formatSnippet(
                                  data.intimeOutTime[0]?.inTimeSnippet
                                )}
                                alt="in-timing-snippet"
                              />
                            ) : (
                              "N/A"
                            )}
                          </td>
                          <td>
                            {/* {data.intimeOutTime[0]?.outTime
                              ? formatTime(data.intimeOutTime[0]?.outTime)
                              : "N/A"} */}

                            {customer.data.country &&
                            data.intimeOutTime[0]?.outTime ? (
                              <TimeFormatter
                                state={customer.data.state}
                                country={customer.data.country}
                                time={data.intimeOutTime[0]?.outTime}
                              />
                            ) : (
                              "NA"
                            )}
                          </td>
                          <td>
                            {data.intimeOutTime[0]?.outTimeSnippet ? (
                              <img
                                style={{ height: "40px", width: "40px" }}
                                src={formatSnippet(
                                  data.intimeOutTime[0]?.outTimeSnippet
                                )}
                                alt="out-timing-snippet"
                              />
                            ) : (
                              "N/A"
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              ) : (
                <Box className="noRecordsFound">
                  <p
                    style={{
                      height: "50%",
                      width: "50%",
                      top: "50%",
                      left: "25%",
                      textAlign: "center",
                      position: "absolute",
                    }}
                  >
                    <h3> No records found for the employee ID.</h3>
                  </p>
                </Box>
              )}
            </Box>
            {logData.length > 0 && (
              <Box className="button_download">
                <button onClick={handleLogDownload}>
                  <span>Download</span>
                </button>
              </Box>
            )}
          </>
        )}
      </Box>
    </Modal>
  );
};

export default LogDetails;
