
import React,{useState,useEffect} from 'react'
import Layout from '../../components/Layout/AdminLayout/Layout'
import { useNotifications } from '../../context/NotificationsProvider'
import moment from "moment";
import axios from "axios";
import NotificationsSkelton from '../../skeleton/notifications/NotificationsSkelton';

const Notifications = () => {
    const {notification,error,loading,  
      setUnreadLength}=useNotifications();
    const handleOperation=async ()=>{
      try{
      const response=await axios.get(`${process.env.REACT_APP_BASE_URL}/notifications/read-notify`, {
                params:{
                  type:"lite",
                },
                headers: {
                  Authorization: localStorage.getItem('token'), 
                },
              })
          
              if (response.data.success && response.data.data) {
                setUnreadLength(null);
              }
      }catch(e){
      console.log(e);
      }
      };
useEffect(()=>{
  handleOperation();
},[]);

  return (
    <Layout>
        <div className="commonBorderForAll"
        style={{ marginTop: "40px", height: "90%" }}
        
        >

<div className="Main_Dashboard_Background">
          <div className="Dashboard_Section">
            {
             error ? <div
              style={{display:"flex",justifyContent:"center",alignItems:"center",height:"80vh",width:"100%"}}
              
              >

                  <h1>{error}</h1>
              </div>:

<div className="Account_Details">
<div
  className="Admin_Account"
  style={{ display: "flex", justifyContent: "space-between" }}
>  
  <h1>Notifications</h1>
 
</div>
{
  !loading ?

<div className="Admin_Details">

 <>
      {Object.keys(notification).map((dateLabel) => (
        <div className="Notifications_parent" key={dateLabel}>
          <div className="heading_notifications">
            {dateLabel}
          </div>
          <div className="message_notifications">
            {notification[dateLabel].map((notification) => (
              <div className="message_child_notifications" key={notification._id}>
                <p>{notification.message}</p>
                <div className="date_notificat">
                  <span>{moment(notification.createdAt).format("MMM DD, YYYY [at] hh:mm A")}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </>
</div>


:
<NotificationsSkelton/>

}


</div>
            }
           
          </div>
        </div>
        </div>
    </Layout>
  )
}

export default Notifications