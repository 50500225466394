import React, { useContext, useState } from "react";
import { customerContext } from "./myProvider";
export const useCustomer = () => {
  return useContext(customerContext);
};

const CustomerProvider = ({ children }) => {
  const [customer, setCustomer] = useState({
    isLogin: false,
    token: "",
    customerId: "",
    data: null,
  });
  const [error, setError] = useState(null);

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  return (
    <customerContext.Provider
      value={{
        customer,
        setCustomer,
        setError,
        error,
        isAuthenticated,
        setIsAuthenticated,
        isUpdate,
        setIsUpdate,
      }}
    >
      {children}
    </customerContext.Provider>
  );
};

export default CustomerProvider;
