import React from "react";
import countriesTimezones from "../../utils/countriesTimezones.json";
export const useAttendanceTable = () => {
  const getTimeByCountry = (country, time, state) => {
    let locale = "en-US";
    let timeZone = "UTC";

    // Normalize the country name to lowercase
    const countryInfo = countriesTimezones[country.toLowerCase()];

    if (countryInfo) {
      locale = countryInfo.locale;

      // Set the timezone based on the country and state
      timeZone = countryInfo.timeZone; // Use the predefined timezone for the country

      // If there are specific state timezones defined, you can incorporate that logic here
      if (countryInfo.timeZones && state) {
        const stateInfo = countryInfo.timeZones[state.toLowerCase()];
        if (stateInfo) {
          timeZone = stateInfo.timeZone; // Override with state timezone if available
        }
      }
    } else {
      console.warn(`No timezone data found for country: ${country}`);
    }

    try {
      // Create a Date object from the UTC time string
      const date = new Date(time); // time is already in ISO 8601 format
      if (isNaN(date.getTime())) {
        throw new Error("Invalid date");
      }

      // Convert the date to the desired timezone
      const formattedTime = new Intl.DateTimeFormat(locale, {
        timeZone: timeZone,
        timeStyle: "long",
        dateStyle: undefined,
      }).format(date);
      const newTime = formattedTime.replace(/\s+[A-Z]+$/, "").trim();

      let newTimePost18 = new Date(newTime);
      const hours = date.getHours();
      if (hours >= 18) {
        newTimePost18 = newTime;
      } else {
        newTimePost18 = "NA";
      }

      const updatedTime = {
        newTime,
        newTimePost18,
      };
      return updatedTime;
    } catch (error) {
      console.error("Error formatting time:", error);
      return "Invalid time or country";
    }
  };

  const getTimeByCountryfForReports = (country, state, time) => {
    let locale = "en-US"; // Default locale
    let timeZone = "UTC"; // Default timezone

    // Get locale and timezone from the JSON file
    const countryInfo = countriesTimezones[country.toLowerCase()];
    if (countryInfo) {
      locale = countryInfo.locale;

      // Determine the timezone based on whether a state is provided
      timeZone = countryInfo.timeZone; // Start with the predefined timezone for the country

      // Check if state-specific time zones exist and override if found
      if (countryInfo.timeZones && state) {
        const stateInfo = countryInfo.timeZones[state.toLowerCase()];
        if (stateInfo) {
          timeZone = stateInfo.timeZone; // Override with state timezone if available
        }
      }
    } else {
      console.warn(`No timezone data found for country: ${country}`);
    }

    try {
      // Get the current date
      const now = new Date();
      // Create a new date object by combining the current date with the given time
      const [hours, minutes, seconds] = time.split(":");
      const date = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        hours,
        minutes,
        seconds
      );

      const formattedTime = new Intl.DateTimeFormat(locale, {
        timeZone: timeZone,
        timeStyle: "long", // Change to 'short' if you want a shorter time format
        dateStyle: undefined, // Exclude date from the formatting
      }).format(date);

      // Remove the timezone abbreviation
      return formattedTime.replace(/\s+[A-Z]+$/, "").trim(); // Removes the last word that consists of uppercase letters
    } catch (error) {
      console.error("Invalid locale or timezone:", error);
      return null;
    }
  };

  return { getTimeByCountry, getTimeByCountryfForReports };
};
