"use strict";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import EmpProvider from "./context/EmpProvider";
import CustomerProvider from "./context/CustomerProvider";
import NotificationsProvider from "./context/NotificationsProvider";
if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <CustomerProvider>
      <EmpProvider>
        <NotificationsProvider>
        <App />
        </NotificationsProvider>
      </EmpProvider>
    </CustomerProvider>
  </React.StrictMode>
);

reportWebVitals();