import React from "react";

const EmployeeInfo = () => {
  return (
    <div className="skeleton-container">
      <div className="upperSkeleton">
        <div className="leftEmpInfo">
          <div className="skeleton-profile-pic"></div>
        </div>
        <div className="rightEmpInfo">
          <div className="skeleton-details">
            {Array.from({ length: 5 }).map((_, index) => (
              <div key={index} className="skeleton-detailHight"></div>
            ))}
          </div>
        </div>
      </div>
      <div className="LowerupperSkeleton">
        <div className="skeleton-details">
          {Array.from({ length: 15 }).map((_, index) => (
            <div key={index} className="skeleton-detail"></div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EmployeeInfo;
