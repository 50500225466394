import React from "react";
import countriesTimezones from "./countriesTimezones.json"; // Adjust the path as necessary

const TimeFormatterForReportDetails = ({ country, state, time }) => {
  const getTimeByCountry = (country, state, time) => {
    let locale = 'en-US'; // Default locale
    let timeZone = 'UTC'; // Default timezone

    // Get locale and timezone from the JSON file
    const countryInfo = countriesTimezones[country.toLowerCase()];
    if (countryInfo) {
      locale = countryInfo.locale;

      // Determine the timezone based on whether a state is provided
      timeZone = countryInfo.timeZone; // Start with the predefined timezone for the country

      // Check if state-specific time zones exist and override if found
      if (countryInfo.timeZones && state) {
        const stateInfo = countryInfo.timeZones[state.toLowerCase()];
        if (stateInfo) {
          timeZone = stateInfo.timeZone; // Override with state timezone if available
        }
      }
    } else {
      console.warn(`No timezone data found for country: ${country}`);
    }

    try {
      // Get the current date
      const now = new Date();
      // Create a new date object by combining the current date with the given time
      const [hours, minutes, seconds] = time.split(':');
      const date = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes, seconds);

      const formattedTime = new Intl.DateTimeFormat(locale, {
        timeZone: timeZone,
        timeStyle: 'long', // Change to 'short' if you want a shorter time format
        dateStyle: undefined // Exclude date from the formatting
      }).format(date);

      // Remove the timezone abbreviation
      return formattedTime.replace(/\s+[A-Z]+$/, '').trim(); // Removes the last word that consists of uppercase letters
    } catch (error) {
      console.error('Invalid locale or timezone:', error);
      return null;
    }
  };

  const currentTime = getTimeByCountry(country, state, time);

  return <div>{currentTime}</div>;
};

export default TimeFormatterForReportDetails;
