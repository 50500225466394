import axios from "axios";
import { useState, useEffect, useRef } from "react";
import Layout from "../../components/Layout/AdminLayout/Layout";
import LiveTrackerSkeleton from "../../skeleton/liveTracker/LiveTrackerSkeleton";
import { useCustomer } from "../../context/CustomerProvider";
import TimeFormatter from "../../utils/gloablDateTimeFunction";

const LiveTracker = () => {
  const [liveTracker, setLiveTracker] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const lastDataRef = useRef([]); // Store the last received data
  const { customer } = useCustomer();
  // Function to perform long polling
  const pollLiveTracker = async () => {
    try {
      // Get the customerId from localStorage

      // Generate the current date in ISO format
      const currentDate = new Date();
      const startOfDay = new Date(
        currentDate.setHours(0, 0, 0, 0)
      ).toISOString(); // Start of the day

      // API call to fetch records for the entire day
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/liveTracker/fetch`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      );

      const data = response.data.data;

      // Check if new data is different from last data
      const isNewData =
        JSON.stringify(data) !== JSON.stringify(lastDataRef.current);
      if (isNewData) {
        // Update the state if new data is found
        lastDataRef.current = data;

        // Create a set to keep track of employee IDs we've seen
        const seenEmployeeIds = new Set();
        const firstRecords = data.filter((record) => {
          if (!seenEmployeeIds.has(record.employeeId)) {
            seenEmployeeIds.add(record.employeeId);
            return true; // Keep this record
          }
          return false; // Skip this record
        });

        setLiveTracker(firstRecords);
        setError(null);
      }
      setLoading(false); // Stop loading after data is fetched
    } catch (err) {
      setError(err.message);
      setLoading(false); // Stop loading on error
    }
  };

  // Use useEffect to implement long polling
  useEffect(() => {
    // Initial call to fetch data
    pollLiveTracker();

    // Set up polling at a 10-second interval (can be adjusted)
    const pollingInterval = setInterval(() => {
      pollLiveTracker();
    }, 8000); // Poll every 10 seconds

    // Clear the interval on component unmount
    return () => clearInterval(pollingInterval);
  }, []);

  // Function to calculate late time
  const calculateLateTime = (inTime, shift, graceTime) => {
    const [a, b] = shift.split("-")[0].split(":");

    // Define office start time and grace period
    const officeStartTime = new Date();
    if (Number(a[0]) === 0) {
      officeStartTime.setHours(Number(a[1]), Number(b), 0, 0);
    } else {
      officeStartTime.setHours(Number(a), Number(b), 0, 0);
    }

    const gracePeriod = Number(graceTime) * 60 * 1000; // Grace time in milliseconds
    const gracePeriodEnd = new Date(officeStartTime.getTime() + gracePeriod);
    const userInTime = new Date(inTime);

    if (userInTime > gracePeriodEnd) {
      const lateTime = userInTime - gracePeriodEnd;
      const hoursLate = Math.floor(lateTime / (1000 * 60 * 60));
      const minutesLate = Math.floor(
        (lateTime % (1000 * 60 * 60)) / (1000 * 60)
      );
      const secondsLate = Math.floor((lateTime % (1000 * 60)) / 1000);

      return {
        message: `${hoursLate}hr ${minutesLate}min ${secondsLate}sec Late`,
        success: true,
      };
    } else {
      return { message: "On Time", success: false };
    }
  };
  return (
    <Layout>
      <section className="liveTracker">
        <div className="one">
          <h3>Live Attendance</h3>
        </div>
        <div className="commonBorderForAll">
          {error ? (
            <div className="logDetailsLoader">
              <h1>No Attendance Found</h1>
            </div>
          ) : (
            <>
              <div className="heading">
                <h1>Today’s Attendance</h1>
              </div>
              {loading && <LiveTrackerSkeleton />}
              <div className="listOfAttendanceEmployee">
                {liveTracker?.map((employee, index) => {
               

                  return (
                    <div className="card" key={index}>
                      <div className="upper"
                     style={{
                      border: !calculateLateTime(
                        employee.inTime,
                        employee?.shift,
                        employee?.graceTime
                      ).success ? "2px solid greenyellow" : "2px solid red"
                    }}
                      
                      >
                       
                        {employee.inTimeSnippet ? (
                          <img
                            src={`data:image/png;base64,${employee.inTimeSnippet}`}
                            alt="employeePic"
                            id="imgLiveTracker"
                          />
                        ) : (
                          "No snippet available"
                        )}
                      </div>
                      <div className="lower">
                        <div className="details">
                          <div className="name_heading">Name</div>
                          <div className="dot">:</div>
                          <div className="name">
                            <b>{employee.employeeName}</b>
                          </div>
                        </div>

                        <div className="details">
                          <div className="name_heading">Emp. ID</div>
                          <div className="dot">:</div>
                          <div className="name"
                      
                          >
                          
                            
                              <b>{employee.employeeId}</b>
                              
                            
                          
                          

                          </div>
                        </div>

                        <div className="details">
                          <div className="name_heading">In Time</div>
                          <div className="dot">:</div>
                          <div className="name">
                            <b>
                              {customer.data.country && customer.data.state && (
                                <TimeFormatter
                                  country={customer.data.country}
                                  state={customer.data.state} 
                                  time={employee?.inTime} 
                                />
                              )}
                            </b>
                          </div>
                        </div>

                       
                        <div className="timeCheck">
                          <p>
                            {calculateLateTime(
                              employee.inTime,
                              employee?.shift,
                              employee?.graceTime
                            ).success && (
                              <b>
                                {
                                  calculateLateTime(
                                    employee.inTime,
                                    employee?.shift,
                                    employee?.graceTime
                                  ).message
                                }
                              </b>
                            )}
                          </p>
                          <p>
                            {!calculateLateTime(
                              employee.inTime,
                              employee?.shift,
                              employee?.graceTime
                            ).success && (
                              <b>
                                {
                                  calculateLateTime(
                                    employee.inTime,
                                    employee?.shift,
                                    employee?.graceTime
                                  ).message
                                }
                              </b>
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </section>
    </Layout>
  );
};

export default LiveTracker;
