import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router-dom";
import { useCustomer } from "../../context/CustomerProvider";
import { useEmployee } from "../../context/EmpProvider";
import axios from "axios";
import { useRightSidebar } from "../../hooks/useRightSidebar/useRightSidebar";
import { useNotifications } from "../../context/NotificationsProvider";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  border: 0,
  outline: 0,
};
const Confirm = ({
  open,
  handleClose,
  message,
  setRightSidebar=()=>{},
  info=null,
  employeeDetails = null,
  filteredEmployees=null,
  setFilteredEmployees=()=>{},
  commonList=null,
}) => {
  // console.log(commonList,"commonList")
  const navigate = useNavigate();
  const { customer, setCustomer } = useCustomer();
  const {socket}=useNotifications();
  // const {setCommonRightSidebar}=useRightSidebar();
  const { handleTerminated, handleSuspended, setEmployee ,employee,setEmployeeDetails,setCommonRightSidebar
  } = useEmployee();
  const [loading, setLoading] = useState(false);
  const handleLogout = () => {
 
     localStorage.removeItem("token");
    setCustomer({
      isLogin: false,
      token: "",
      customerId: "",
      data: null,
    });
    setEmployee([]);
    navigate("/login");
  };

  const deleteRecords = async () => {
    try {
    
      setLoading(true);
      const { data } = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/liveTracker/delete-record`,
        {
          data: { customerID: "OKFS2" },
          headers:{
            Authorization:localStorage.getItem("token")
          }
        },
        
      );
      if (data?.success) {
        
        setLoading(false);

        handleClose();
      }
      // setUserData(response.data);
    } catch (error) {
      setLoading(false);

      handleClose();
      console.error("Error fetching user data:", error);
    }
  };
  const nominateHr = async () => {
    try {
      setLoading(true);
      const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/employees/update-hr`, {
        employeeId: info?.id,
      }
    ,{
      headers:{
        Authorization:localStorage.getItem("token")
      }
    }
    
    );

      if (response?.data?.success) {
        setLoading(false);
        handleClose();

        const allHrFalse = employee?.map((e) => {
         return {...e,isHr:false}
        });

        const info = allHrFalse?.map((e) => {
          if (e?._id === response?.data?.data?._id) {

            if (response?.data?.data) {
              setEmployeeDetails(response?.data?.data); 
            }

            return response?.data?.data;
          }
          return e;
        });
      //  console.log(filteredEmployees);
      const filterEmployees = filteredEmployees || [];

       const updatedEmployees = filterEmployees?.map((employee) => {
        if (employee._id === response?.data?.data?._id) {

          if (response?.data?.data) {
            setEmployeeDetails(response?.data?.data); 
          }

          return {
            ...employee,
            isHr: true
          };
        }
       
        return employee;
      });

        setFilteredEmployees(updatedEmployees)
        setEmployee(info);
        setCustomer((prev) => ({
          ...prev, 
          data: {
            ...prev.data,
            isHr: {
              employeeId: response?.data?.data?.employeeId,
              isHrAssign: true,
            },
          },
        }));
        
        setRightSidebar(false);
      }
    } catch (error) {
      handleClose();
      setLoading(false);
      setRightSidebar(false);
    }
  };

  const handleReCapture = async () => {
    try {
      const listOfReTrend = commonList?.map((ir) => {
        return {
          employeeID: ir?._id,
          isReTrend: ir?.isReTrend,
        };
      });
  
      setLoading(true);
  
      
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/employees/reTrend-employee`,
        {
          listOfReTrend,
        },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
  
      if (response?.data?.success) {
        setLoading(false);
  
       
        const updatedEmployees = employee?.map((e) => {
          const updatedItem = listOfReTrend?.find((item) => item.employeeID === e._id);
          return updatedItem ? { ...e, isReTrend: updatedItem.isReTrend } : e;
        });

  
       
        const filterEmployees = filteredEmployees || [];
  
        const updatedFilteredEmployees = filterEmployees.map((e) => {
          const updatedItem = listOfReTrend?.find((item) => item.employeeID === e._id);
          return updatedItem ? { ...e, isReTrend: updatedItem.isReTrend } : e;
        });
  
        // console.log(updatedEmployees, "updatedEmployees");
  
      
        socket.emit('employee_for_reTrends', {
          message: 'Employee retrend status updated',
          customerId:customer?.data?._id
        });

        setFilteredEmployees(updatedFilteredEmployees);
        setEmployee(updatedEmployees);
        setEmployeeDetails(updatedEmployees[0]);
        handleClose();
        setCommonRightSidebar(false);
      }
    } catch (e) {
      setLoading(false);
      handleClose();
      setCommonRightSidebar(true);
      console.error(e);
    }
  };
  

  const handleConfirm = async () => {
    // console.log(message?.type);
    if (message?.type === "Logout") {
      handleLogout();
    } else if (message?.type === "Warning") {
      deleteRecords();
    } else if (message?.type === "terminate") {
      try {
        const isSuccess = await handleTerminated(employeeDetails);
        if (isSuccess) {
          handleClose();
        }
      } catch (e) {
        handleClose();
        console.log(e);
      }
    } else if (message?.type === "terminate") {
      return;
    } else if (message?.type === "Suspend" || message?.type === "Suspended") {
      try {
        const type = message?.type;
        const isSuccess = await handleSuspended(employeeDetails, type);
        if (isSuccess) {
          handleClose();
        }
      } catch (e) {
        handleClose();
      }
    }else if(message?.type==="WarningHr"){
      setRightSidebar(true);
      handleClose();
    }else if(message?.type==="WarningChangeHr"){
      if(info?.name){
        nominateHr();
      }
   
    }else if(message?.type==="recapture"){
      handleClose();
      setCommonRightSidebar(true);
    }else if(message?.type==="WarningSendReCapture"){
      handleReCapture();
    }
  };




  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="Popup_Box">
          <div className="Popup_Text"
         
          >
            <h1
         
            
            >{message?.title}</h1>
          </div>
          <div className="contentAlter"
           style={{textAlign:"center"}}
          >
            <p>{message?.content}</p>
          </div>

          <div className="Popup_Button">
            {
              message?.type==="WarningChangeHr"?
           info?.name &&

              <button className="loginBtn" onClick={handleConfirm}>
              {loading ? "Loading.." : `${message?.leftBtn}`}
            </button>
              
              :

              <button className="loginBtn" onClick={handleConfirm}>
              {loading ? "Loading.." : `${message?.leftBtn}`}
            </button>
            }
           
            <button
              className="cancelBtn"
              onClick={() => {
                handleClose();
              }}
            >
              {message?.rightBtn}
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default Confirm;
