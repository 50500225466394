import React, { useState, useEffect } from "react";
import countriesTimezones from "./countriesTimezones.json"; // Adjust the path as necessary

const TimeFormatter = ({ country }) => {
  const [currentTime, setCurrentTime] = useState("");

  // Function to get the time for the specified country
  const getTimeByCountry = (country) => {
    const countryInfo = countriesTimezones[country.toLowerCase()] || {};

    const locale = countryInfo.locale || "en-US"; // Default locale
    const timeZone = countryInfo.timeZone || "UTC"; // Default timezone

    try {
      const formattedTime = new Intl.DateTimeFormat(locale, {
        timeZone: timeZone,
        timeStyle: "long", // Change this to 'short' if you want a shorter time format
        dateStyle: undefined, // Exclude date from the formatting
      }).format(new Date());

      // Remove the timezone abbreviation (last part of the formatted string)
      return {
        time: formattedTime.replace(/\s+[A-Z]+$/, "").trim(), // Removes the last word that consists of uppercase letters
        locale,
        timeZone,
      };
    } catch (error) {
      console.error("Invalid locale or timezone:", error);
      return null;
    }
  };

  // Effect hook to update the time every second
  useEffect(() => {
    const updateTime = () => {
      const timeData = getTimeByCountry(country);
      if (timeData) {
        setCurrentTime(timeData);
      }
    };

    // Set the interval to update the time every second
    const intervalId = setInterval(updateTime, 1000);

    // Call updateTime immediately on component mount
    updateTime();

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [country]);

  // Destructure currentTime state
  const { time, locale: currentLocale, timeZone } = currentTime || {};

  return (
    <div>
      {/* Displaying all information in one line */}
      <div>
        Current Time: {time || "Loading..."} | Locale: {currentLocale} | Time
        Zone: {timeZone}
      </div>
    </div>
  );
};

export default TimeFormatter;
