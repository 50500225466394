import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Animation = () => {
  const [scattered, setScattered] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const isRegistered = localStorage.getItem("token");
    const timeout = setTimeout(() => {
      setScattered(false);
    }, 500);
    setTimeout(() => {
      if (isRegistered) {
        navigate("/dashboard");
      } else {
        navigate("/login");
      }
    }, 4000);
    return () => clearTimeout(timeout);
  }, [navigate]);

  const getRandomOffset = (maxOffset) => {
    const sign = Math.random() > 0.5 ? 1 : -1;
    return sign * Math.random() * maxOffset;
  };

  const getRandomPosition = (width, height) => ({
    left: `${getRandomOffset(width)}px`,
    top: `${getRandomOffset(height)}px`,
  });

  const circles = [
    { cx: 696, cy: 536, r: 14 },
    { cx: 664.5, cy: 532.5, r: 11.5 },
    { cx: 698, cy: 569, r: 14 },
    { cx: 663.5, cy: 566.5, r: 10.5 },
    { cx: 928.25, cy: 574.25, r: 15.25 },
    { cx: 925.25, cy: 611.25, r: 15.25 },
    { cx: 862.25, cy: 611.25, r: 15.25 },
    { cx: 800.25, cy: 574.25, r: 15.25 },
    { cx: 862.25, cy: 572.25, r: 15.25 },
    { cx: 748.25, cy: 609.25, r: 15.25 },
    { cx: 862.25, cy: 535.25, r: 15.25 },
    { cx: 929.25, cy: 534.25, r: 15.25 },
    { cx: 742.25, cy: 539.25, r: 15.25 },
    { cx: 978.25, cy: 609.25, r: 15.25 },
    { cx: 984.25, cy: 539.25, r: 15.25 },
    { cx: 862, cy: 650, r: 15 },
    { cx: 799.25, cy: 534.25, r: 15.25 },
    { cx: 803.25, cy: 611.25, r: 15.25 },
    { cx: 744.25, cy: 575.25, r: 15.25 },
    { cx: 981.25, cy: 575.25, r: 15.25 },
    { cx: 862, cy: 496, r: 15 },
    { cx: 800.25, cy: 495.25, r: 14.25 },
    { cx: 807.25, cy: 649.25, r: 14.25 },
    { cx: 928.25, cy: 495.25, r: 14.25 },
    { cx: 701, cy: 600, r: 14 },
    { cx: 753, cy: 643, r: 14 },
    { cx: 973, cy: 643, r: 14 },
    { cx: 986, cy: 502, r: 14 },
    { cx: 921.25, cy: 649.25, r: 14.25 },
    { cx: 740, cy: 502, r: 14 },
    { cx: 1028, cy: 569, r: 14 },
    { cx: 1030, cy: 536, r: 14 },
    { cx: 1025, cy: 600, r: 14 },
    { cx: 862, cy: 691, r: 14 },
    { cx: 862.75, cy: 461.75, r: 13.75 },
    { cx: 759, cy: 675, r: 13 },
    { cx: 918, cy: 686, r: 13 },
    { cx: 1021, cy: 630, r: 13 },
    { cx: 967, cy: 675, r: 13 },
    { cx: 695, cy: 505, r: 13 },
    { cx: 810, cy: 686, r: 13 },
    { cx: 1031, cy: 505, r: 13 },
    { cx: 799.75, cy: 460.75, r: 12.75 },
    { cx: 705, cy: 630, r: 13 },
    { cx: 928.75, cy: 460.75, r: 12.75 },
    { cx: 927, cy: 426, r: 12 },
    { cx: 862.75, cy: 427.75, r: 12.75 },
    { cx: 987.75, cy: 469.75, r: 12.75 },
    { cx: 986.75, cy: 438.75, r: 12.75 },
    { cx: 862.75, cy: 723.75, r: 12.75 },
    { cx: 800, cy: 426, r: 12 },
    { cx: 739.75, cy: 438.75, r: 12.75 },
    { cx: 738.75, cy: 469.75, r: 12.75 },
    { cx: 812.5, cy: 720.5, r: 12.5 },
    { cx: 914.5, cy: 720.5, r: 12.5 },
    { cx: 764.5, cy: 708.5, r: 12.5 },
    { cx: 961.5, cy: 708.5, r: 12.5 },
    { cx: 862.25, cy: 396.25, r: 12.25 },
    { cx: 710.75, cy: 658.75, r: 11.75 },
    { cx: 862, cy: 760, r: 12 },
    { cx: 1063.5, cy: 533.5, r: 11.5 },
    { cx: 770.75, cy: 740.75, r: 11.75 },
    { cx: 955.75, cy: 740.75, r: 11.75 },
    { cx: 1015.75, cy: 658.75, r: 11.75 },
    { cx: 818.75, cy: 756.75, r: 11.75 },
    { cx: 909.75, cy: 756.75, r: 11.75 },
    { cx: 694.75, cy: 474.75, r: 11.75 },
    { cx: 925.5, cy: 392.5, r: 11.5 },
    { cx: 1030.5, cy: 445.5, r: 11.5 },
    { cx: 1031.75, cy: 474.75, r: 11.75 },
    { cx: 695.5, cy: 444.5, r: 11.5 },
    { cx: 802.5, cy: 392.5, r: 11.5 },
    { cx: 741.5, cy: 406.5, r: 11.5 },
    { cx: 715.5, cy: 687.5, r: 11.5 },
    { cx: 984.5, cy: 406.5, r: 11.5 },
    { cx: 1010.5, cy: 687.5, r: 11.5 },
    { cx: 1064.5, cy: 567.5, r: 10.5 },
    { cx: 1004.5, cy: 715.5, r: 10.5 },
    { cx: 669.5, cy: 595.5, r: 10.5 },
    { cx: 1058.5, cy: 596.5, r: 10.5 },
    { cx: 721.5, cy: 715.5, r: 10.5 },
    { cx: 697.5, cy: 416.5, r: 10.5 },
    { cx: 1029.5, cy: 416.5, r: 10.5 },
    { cx: 805.25, cy: 363.25, r: 10.25 },
    { cx: 923.25, cy: 363.25, r: 10.25 },
    { cx: 980.25, cy: 376.25, r: 10.25 },
    { cx: 746.25, cy: 376.25, r: 10.25 },
    { cx: 862, cy: 365, r: 10 },
    { cx: 862, cy: 793, r: 10 },
    { cx: 1049, cy: 626, r: 9 },
    { cx: 678, cy: 626, r: 9 },
    { cx: 701.25, cy: 389.25, r: 9.25 },
    { cx: 1025.25, cy: 389.25, r: 9.25 },
    { cx: 920, cy: 336, r: 9 },
    { cx: 808, cy: 336, r: 9 },
    { cx: 975, cy: 350, r: 9 },
    { cx: 751, cy: 350, r: 9 },
    { cx: 823, cy: 790, r: 9 },
    { cx: 949, cy: 772, r: 9 },
    { cx: 777, cy: 772, r: 9 },
    { cx: 905, cy: 790, r: 9 },
    { cx: 728.25, cy: 743.25, r: 8.25 },
    { cx: 708.5, cy: 365.5, r: 8.5 },
    { cx: 998.25, cy: 743.25, r: 8.25 },
    { cx: 1017.5, cy: 365.5, r: 8.5 },
    { cx: 862.5, cy: 338.5, r: 8.5 },
    { cx: 862.5, cy: 818.5, r: 7.5 },
    { cx: 862, cy: 315, r: 7 },
    { cx: 917.75, cy: 313.75, r: 6.75 },
    { cx: 969, cy: 328, r: 7 },
    { cx: 810.75, cy: 313.75, r: 6.75 },
    { cx: 757, cy: 328, r: 7 },
    { cx: 718.5, cy: 347.5, r: 6.5 },
    { cx: 862, cy: 295, r: 6 },
    { cx: 1007.5, cy: 347.5, r: 6.5 },
    { cx: 902, cy: 813, r: 6 },
    { cx: 943, cy: 796, r: 6 },
    { cx: 785, cy: 796, r: 6 },
    { cx: 826, cy: 813, r: 6 },
    { cx: 739.25, cy: 767.25, r: 5.25 },
    { cx: 987.25, cy: 767.25, r: 5.25 },
    { cx: 765.25, cy: 307.25, r: 5.25 },
    { cx: 815.25, cy: 295.25, r: 5.25 },
    { cx: 913.25, cy: 295.25, r: 5.25 },
    { cx: 961.25, cy: 307.25, r: 5.25 },
    { cx: 729, cy: 331, r: 5 },
    { cx: 997, cy: 331, r: 5 },
    { cx: 794.5, cy: 818.5, r: 4.5 },
    { cx: 828.5, cy: 832.5, r: 4.5 },
    { cx: 900.5, cy: 832.5, r: 4.5 },
    { cx: 932.5, cy: 818.5, r: 4.5 },
    { cx: 862.5, cy: 842.5, r: 3.5 },
    { cx: 979.5, cy: 787.5, r: 3.5 },
    { cx: 862.5, cy: 275.5, r: 3.5 },
    { cx: 749.5, cy: 787.5, r: 3.5 },
    { cx: 819, cy: 280, r: 3 },
    { cx: 909, cy: 281, r: 3 },
    { cx: 950, cy: 290, r: 3 },
    { cx: 779, cy: 290, r: 3 },
    { cx: 984.75, cy: 316.75, r: 2.75 },
    { cx: 741.75, cy: 316.75, r: 2.75 },
  ];

  return (
    <div className="Main_Background">
      <svg
        width="1728"
        height="1117"
        viewBox="0 0 1728 1117"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ position: "relative", width: "100%", height: "100%" }}
      >
        {circles.map((circle, index) => (
          <circle
            key={index}
            cx={circle.cx}
            cy={circle.cy}
            r={circle.r}
            fill="#0E59A4"
            style={{
              transition: "all 2s ease",
              transform: scattered
                ? `translate(${getRandomPosition(1728, 1117).left}, ${
                    getRandomPosition(1728, 1117).top
                  })`
                : "translate(0, 0)",
            }}
          />
        ))}
      </svg>
    </div>
  );
};

export default Animation;
