
import React, { useState } from 'react';
import { DatePicker } from 'antd';
import "./dateRange.css"
import { UseDatePicker } from '../../hooks/useDatePicker/UseDatePicker';
import { useEmployee } from '../../context/EmpProvider';
import moment from 'moment';
const { RangePicker } = DatePicker;
const DateRange = () => {
  const disabledDate = (current) => {
    // Can not select days after today
    return current && current > moment().endOf('day');
  };
    const {selectedDate,setSelectedDate}=useEmployee();
    const onChange = (date,dateString) => {
        const [a,b]=dateString;
        // console.log(a,b);
        setSelectedDate({
    endDate:b,
    startDate:a
        })
        // console.log("ab",a,b)
      };
  return (
    <RangePicker 
    onChange={onChange}
    disabledDate={disabledDate}
    //  format="DD-MM-YYYY"
     allowClear={false}
   
    />
  )
}

export default DateRange