import React, { useState } from "react";
import Face from "../../../assets/images/face.svg";
import backGroundImage from "../../../assets/images/from_back.svg";
import facesenseHeading from "../../../assets/images/faceHeading.svg";
import EyeOpen from "../../../assets/images/eyeOpen.svg";
import EyeClose from "../../../assets/images/eyeClose.svg";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useCustomer } from "../../../context/CustomerProvider";
import Logo from "../../../assets/images/Okulr_Logo.svg";
const Login = () => {
  const navigate = useNavigate();
  const { customer, setCustomer, isAuthenticated, setIsAuthenticated } =
    useCustomer();
  const [error, setError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [message, setMessage] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setError(false);
    setMessage("");
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailBlur = () => {
    if (!validateEmail(formData.email)) {
      setMessage("Invalid Email");
      setError(true);
    } else {
      setMessage("");
      setError(false);
    }
  };

  const handleClick = async () => {
    if (!error && formData.email !== "" && formData.password !== "") {
      console.log("Form Data:", formData);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/user/login`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.status_code === 200) {
        setIsAuthenticated(true);
        setCustomer({
          ...customer,
          isLogin: true,
          token: response.data.token,
          customerId: response.data.customerid,
          data: response.data.data,
        });
       
        localStorage.setItem("token", response.data.token);

        navigate("/dashboard");
      } else {
        setError(true);
        setMessage(response.data.message);
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleClick();
    }
  };

  const handleForgotPass = () => {
    navigate("/forgotpassword");
  };

  return (
    <>
      <header>
        <div className="Header_Component">
          <img src={Logo} alt="Okulr Techminds" />
        </div>
      </header>
      <div className="Main_Background">
        <div className="Left_Section">
          <div className="Left_Section_Contents">
            <img src={Face} alt="Facesense" />
            <h1>Your Face is Your ID</h1>
            <p>The new era has arrived !</p>
          </div>
        </div>
        <div className="Right_Section">
          <img src={backGroundImage} className="Form_Background" alt="Back" />
          <div className="Right_Section_Contents">
            <img src={facesenseHeading} alt="Facesense" />
            <div className="Input_Box">
              <h2>Login</h2>
              <div className="Input_Field">
                <div className="Label">Email</div>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  onBlur={handleEmailBlur}
                  required
                />
              </div>
              <div className="Input_Field">
                <div className="Label">Password</div>
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown} // Listen for "Enter" key
                  required
                />
                {showPassword ? (
                  <img
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                    src={EyeOpen}
                    alt="Eye Button"
                  />
                ) : (
                  <img
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                    src={EyeClose}
                    alt="Eye Button"
                  />
                )}

                <span onClick={handleForgotPass}>Forgot Password?</span>
              </div>
            </div>

            <div
              className="Email_Sent"
              style={{ color: error ? "#eb0100" : "#01a601" }}
            >
              {message}
            </div>

            <button type="button" onClick={handleClick}>
              Login
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
