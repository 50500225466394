import React from "react";
import dashboardIcons from "../../../assets/images/DashboardIcons.svg";
import liveTracker from "../../../assets/images/live-tracker-icons.svg";
import reportsDetails from "../../../assets/images/reports_details_icons.svg";
import empDetails from "../../../assets/images/employee_details_blue_icons.svg";
import okulrDownLogo from "../../../assets/images/okulrDownLogo.svg";

import { useLocation, useNavigate } from "react-router-dom";

const Sidebar = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const navLink = [
    {
      name: "Dashboard",
      path: "/dashboard",
      icons_url: dashboardIcons,
    },
    {
      name: "Live Tracker",
      path: "/live-tracker",
      icons_url: liveTracker,
    },
    {
      name: "Employee Details",
      path: "/emp-details",
      icons_url: empDetails,
    },
    {
      name: "Reports",
      path: "/reports-cards",
      icons_url: reportsDetails,
    },
  ];
  return (
    <div className="sidebar"
    
    >
      <div>
      {navLink.map((n, i) => (
        <div
          key={i}
          className={`sidebarWrapper ${
            pathname === n?.path ? "bgApplied" : ""
          }`}
        >
          <div
            className="sideNavContainer"
            key={i}
            onClick={() => navigate(`${n?.path}`)}
          >
            <div
              className={`sidebarNavIcons
               
              `}
            >
              <img src={n?.icons_url} alt={n?.name} />
              <span className="sidebarIconsName">{n?.name}</span>
            </div>
          </div>
        </div>
      ))}
    </div>
      <div className="lastBottomSidebar">

<img src={okulrDownLogo} alt="okulrDownLogo" />
 {/* <span>
v.1.2
</span> */}
      </div>
    </div>
  );
};

export default Sidebar;
