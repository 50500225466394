import React, { useState, useEffect, useRef } from "react";
import Modal from "@mui/material/Modal";
import axios from "axios";
import Box from "@mui/material/Box";
import Dropdown from "../../assets/images/dropdown.svg";
import Upload from "../../assets/images/Upload.svg";
import { useEmployee } from "../../context/EmpProvider";
import { Country, State, City } from "country-state-city";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  border: 0,
  outline: 0,
  height: "70%",
  width: "60%",
  borderRadius: "20px",
  backgroundColor: "#fff",
};

const BaseURL = process.env.REACT_APP_BASE_URL;
const EditProfile = ({
  open,
  handleClose: modelClose,
  setEmployeeDetails,
  employeeDetails,
}) => {
  const { isUpdate, setIsUpdate, employee, setEmployee } = useEmployee();
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");

  const [employeeidformats, setEmployeeIDFormat] = useState([]);
  const [cameras, setCameras] = useState([]);
  const [locations, setLocations] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [employmenttypes, setEmploymentTypes] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [isRegisterEnabled, setRegisterEnabled] = useState(false);
  const [showdetail, setShowDetail] = useState(false);
  const [showvideo, setShowVideo] = useState(false);
  const [camdetails, setCamdetails] = useState({});
  const [showdone, setShowDone] = useState(false);
  const [showtraining, setShowTraining] = useState(false);
  const [buttontext, setButtonText] = useState("Check for Preview");
  const [filteredDepartmentIds, setFilteredDepartmentIds] = useState([]);
  const [loader, setLoader] = useState(false);
  const [faceregistered, setFaceRegistered] = useState(false);
  const videoRef = useRef(null);
  const hlsRef = useRef(null);
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(false);


  

  const [formData, setFormData] = useState({
    customerid: "",
    employeeId: "",
    firstName: "",
    lastName: "",
    dob: "",
    gender: "",
    emailPersonal: "",
    emailOfficial: "",
    mobileNo: "",
    country: selectedCountry,
    state: selectedState,
    city: "",
    addressLine1: "",
    addressLine2: "",
    pincode: "",
    joiningDate: "",
    workLocation: "",
    department: "",
    designation: "",
    employmentType: "",
    blockOrBuildingNo: "",
    shift: "",
    idProofType: "",
    idProofNo: "",
    idProofPhoto: {
      name: "",
      data: "",
    },
    profilePhoto: null,
  });
  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  useEffect(() => {
    setFormData({
      customerid: employeeDetails?.customerid,
      employeeId: employeeDetails?.employeeId,
      firstName: employeeDetails?.firstName,
      lastName: employeeDetails?.lastName,
      dob: formatDate(employeeDetails?.dob),
      gender: employeeDetails?.gender,
      emailPersonal: employeeDetails?.emailPersonal,
      emailOfficial: employeeDetails?.emailOfficial,
      mobileNo: employeeDetails?.mobileNo,
      country: employeeDetails?.country,
      state: employeeDetails?.state,
      city: employeeDetails?.city,
      addressLine1: employeeDetails?.addressLine1,
      addressLine2: employeeDetails?.addressLine2,
      pincode: employeeDetails?.pincode,
      joiningDate: formatDate(employeeDetails?.joiningDate),
      workLocation: employeeDetails?.workLocation,
      department: employeeDetails?.department,
      designation: employeeDetails?.designation,
      employmentType: employeeDetails?.employmentType,
      blockOrBuildingNo: employeeDetails?.blockOrBuildingNo,
      shift: employeeDetails?.shift,
      idProofType: employeeDetails?.idProofType,
      idProofNo: employeeDetails?.idProofNo,
      idProofPhoto: {
        name: employeeDetails?.idProofPhoto?.name,
        data: employeeDetails?.idProofPhoto?.data,
      },
      profilePhoto: employeeDetails?.profilePhoto,
    });
  }, [employeeDetails]);

  useEffect(() => {
    const selectedCountry = Country.getAllCountries().find(
      (country) => country.name === employeeDetails?.country
    );
    setSelectedCountry(selectedCountry);
    const selectedState = State.getStatesOfCountry(
      selectedCountry?.isoCode
    ).find((state) => state.name === employeeDetails?.state);
    setSelectedState(selectedState);
  }, [employeeDetails]);
  
  const [inputErrors, setInputErrors] = useState({
    employeeIdPart1: false,
    employeeIdPart2: false,
    employeeIdPart3: false,
    firstName: false,
    lastName: false,
    dob: false,
    gender: false,
    emailPersonal: false,
    emailOfficial: false,
    mobileNo: false,
    country: false,
    state: false,
    city: false,
    addressLine1: false,
    addressLine2: false,
    pincode: false,
    joiningDate: false,
    workLocation: false,
    department: false,
    designation: false,
    employmentType: false,
    blockOrBuildingNo: false,
    shift: false,
    idProofType: false,
    idProofNo: false,
    idProofPhoto: false,
    camera: false,
    profilePhoto: false,
  });



  const handleInputChange = (e) => {
    const { name, value, type, files } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "file" ? files[0] : value,
    }));

    setInputErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));
  };

  const handleInputBlur = (e) => {
    const { name, value } = e.target;

    if (value.trim() === "") {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        [name]: true,
      }));
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const allowedTypes = [
        "application/pdf",
        "image/png",
        "image/jpg",
        "image/jpeg",
      ];

      if (allowedTypes.includes(file.type)) {
        const reader = new FileReader();

        reader.onloadend = () => {
          setFormData({
            ...formData,
            idProofPhoto: {
              name: file.name,
              data: reader.result,
            },
          });
        };

        reader.readAsDataURL(file);
        setSelectedFileName(file.name);
      } else {
        e.target.value = null;
      }
    }
  };

  const handleSubmit = async () => {
    console.log(formData)

    if (!formData.idProofNo) {
      setInputErrors((prev) => ({
        ...prev,
        idProofNo: true,
      }));
      return;
    }

    if (!formData.idProofPhoto) {
      setInputErrors((prev) => ({
        ...prev,
        idProofPhoto: true,
      }));
      return;
    }

    if (
      !formData.pincode ||
      isNaN(formData.pincode) ||
      formData.pincode.length !== 6 ||
      Number(formData.pincode) <= 0
    ) {
      setInputErrors((prev) => ({
        ...prev,
        pincode: true,
      }));
      return;
    }
    if (!formData.dob) {
      setInputErrors((prev) => ({
        ...prev,
        dob: true,
      }));
      return;
    }

    const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (
      !formData.emailPersonal ||
      formData.emailPersonal.length === 0 ||
      !EMAIL_REGEX.test(formData.emailPersonal)
    ) {
      setInputErrors((prev) => ({
        ...prev,
        emailPersonal: true,
      }));
      return;
    }

    if (
      !formData.emailOfficial ||
      formData.emailOfficial.length === 0 ||
      !EMAIL_REGEX.test(formData.emailOfficial)
    ) {
      setInputErrors((prev) => ({
        ...prev,
        emailOfficial: true,
      }));
      return;
    }

    if (
      !formData.mobileNo ||
      isNaN(formData.mobileNo) ||
      formData.mobileNo.length !== 10 ||
      Number(formData.mobileNo) <= 0
    ) {
      setInputErrors((prev) => ({
        ...prev,
        mobileNo: true,
      }));
      return;
    }
   
    const validateAddressFields = (address) => {
   
      const MAX_ADDRESS_LENGTH = 100; 
      const ADDRESS_REGEX = /^[a-zA-Z0-9\s,.#-]+$/; 

      
      address = address.trim();

      
      if (!address) {
        return "Address1 is required";
      }

 
      return null;
    };

    
    const addressError1 = validateAddressFields(formData.addressLine1);

    if (addressError1) {
      setInputErrors((prev) => ({
        ...prev,
        addressLine1: true,
      }));
      return;
    }
    const addressError2 = validateAddressFields(formData.addressLine2);
    if (addressError2) {
      setInputErrors((prev) => ({
        ...prev,
        addressLine2: true,
      }));
      return;
    }

    if (!formData.country) {
      setInputErrors((prev) => ({
        ...prev,
        country: true,
      }));
      return;
    }
    if (!selectedCountry) {
      setInputErrors((prev) => ({
        ...prev,
        country: true,
      }));
      return;
    }
    if (!selectedState) {
      setInputErrors((prev) => ({
        ...prev,
        state: true,
      }));
      return;
    }
    if (!formData.state) {
      setInputErrors((prev) => ({
        ...prev,
        state: true,
      }));
      return;
    }

    if (!formData.city) {
      setInputErrors((prev) => ({
        ...prev,
        city: true,
      }));
      return;
    }
    const info = {
      firstName: formData.firstName || "",
      lastName: formData.lastName || "",
      dob: formData.dob || "",
      gender: formData.gender || "",
      emailPersonal: formData.emailPersonal || "",
      emailOfficial: formData.emailOfficial || "",
      mobileNo: formData.mobileNo || "",
      address: `${formData.addressLine1 || ""}, ${
        formData.addressLine2 || ""
      }, ${formData.city || ""}, ${formData.state || ""}, ${
        formData.country || ""
      } - ${formData.pincode || ""}`,
      dateOfJoining: formData.joiningDate || "",
      department: formData.department || "",
      designation: formData.designation || "",
      employmentType: formData.employmentType || "",
      workLocation: formData.workLocation || "",
      blockOrBuildingNo: formData.blockOrBuildingNo || "",
      shift: formData.shift || "",
      idProofType: formData.idProofType || "",
      idProofNo: formData.idProofNo || "",
      country: formData.country || "",
      state: formData.state || "",
      city: formData.city || "",
      pincode: formData.pincode || "",
      idProofPhoto: {
        name: formData.idProofPhoto?.name || "",
        data: formData.idProofPhoto?.data || "",
      },
      addressLine1: formData?.addressLine1,
      addressLine2: formData?.addressLine2,
    };
   
    try {
      setLoading(true);
      const response = await axios.put(`${BaseURL}/employees/edit`, info, {
        params: { employeeId: employeeDetails?.employeeId },
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        
      });
      // console.log(response.data?.data);
      // console.log(formData);
      if (response?.data?.success) {
        setLoading(false);

        const info = employee?.map((e) => {
          if (e?._id === response?.data?.data?._id) {
            if (response?.data?.data) {
              // localStorage.setItem(
              //   "EmployeeDetails",
              //   JSON.stringify(response?.data?.data)
              // );
              setEmployeeDetails(response?.data?.data); // Set the details of the first employee
            }

            return response?.data?.data;
          }
          return e;
        });
        setEmployee(info);
        setSelectedFileName("");
        // setIsUpdate(!isUpdate);
        modelClose();
        setFormData({
          customerid: "",
          employeeIdPart1: "",
          employeeIdPart2: "",
          employeeIdPart3: "",
          firstName: "",
          lastName: "",
          dob: "",
          gender: "",
          emailPersonal: "",
          emailOfficial: "",
          mobileNo: "",
          country: selectedCountry,
          state: selectedState,
          city: "",
          addressLine1: "",
          addressLine2: "",
          pincode: "",
          joiningDate: "",
          workLocation: "",
          department: "",
          designation: "",
          employmentType: "",
          blockOrBuildingNo: "",
          shift: "",
          idProofType: "",
          idProofNo: "",
          idProofPhoto: {
            name: "",
            data: "",
          },
        });
      }
    } catch (error) {
      setLoading(false);

      // console.log(error);
    }
  };


  useEffect(() => {
    const fetchLocations = `${BaseURL}/masters/location/get`;
    const fetchCameras = `${BaseURL}/camera/getcam`;
    const fetchShifts = `${BaseURL}/masters/shiftname/get`;
    const fetchBuildings = `${BaseURL}/masters/building/get`;
    const fetchDepartments = `${BaseURL}/masters/department/get`;
    const fetchDesignations = `${BaseURL}/masters/designation/get`;
    const fetchEmploymentType = `${BaseURL}/masters/employmenttype/get`;
    const fetchEmploymentIDFormat = `${BaseURL}/masters/employeeidformat/get`;

   

    const fetchData = async () => {
      try {
        const [
          locationRes,
          cameraRes,
          shiftRes,
          buildingRes,
          departmentRes,
          designationRes,
          employmenttypeRes,
          employmentidformatRes,
        ] = await Promise.all([
          axios.get(fetchLocations, {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }),
          axios.get(fetchCameras, {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }),
          axios.get(fetchShifts, {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }),
          axios.get(fetchBuildings, {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }),
          axios.get(fetchDepartments, {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }),
          axios.get(fetchDesignations, {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }),
          axios.get(fetchEmploymentType, {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }),
          axios.get(fetchEmploymentIDFormat, {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }),
        ]);
        setLocations(locationRes.data.locations);
        setCameras(cameraRes.data.cameras);
        setShifts(shiftRes.data.shiftnames);
        setBuildings(buildingRes.data.buildings);
        setDepartments(departmentRes.data.departments);
        setDesignations(designationRes.data.designations);
        setEmploymentTypes(employmenttypeRes.data.employmenttypes);
        setEmployeeIDFormat(employmentidformatRes.data.employmentidformats);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (employeeidformats?.[0]?.employeeId.length > 0) {
      const firstCompany = employeeidformats[0].employeeId[0];
      setFilteredDepartmentIds(
        employeeidformats[0].employeeId.filter(
          (format) => format.companyid === firstCompany.companyid
        )
      );
      setFormData((prevFormData) => ({
        ...prevFormData,
        employeeIdPart1: firstCompany.companyid,
        employeeIdPart2: firstCompany.departmentid,
        employeeIdPart3: firstCompany.employeeid,
      }));
    }
  }, [employeeidformats]);


  const handleImageClick = () => {
    fileInputRef.current.click();
  };


  return (
    <Modal
      open={open}
      onClose={modelClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box style={style} id="editProfile">
        <div className="Main_Section">
          <div className="Employee_Register">
            <h1>
              Edit Employee <span>*</span>
            </h1>
            <div className="Employee_Input_Section">
              <div className="Employee_Inputs">
                <div className="Input_Field">
                  <div className="Label">Emp Id</div>
                  <input
                    type="text"
                    name="empId"
                    value={formData.employeeId}
                    onChange={handleInputChange}
                    disabled
                  />
                </div>

                <div className="Input_Field">
                  <div className="Label">First Name</div>
                  <input
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    disabled
                  />
                </div>
              </div>
              {/* arjun */}
              <div className="Employee_Inputs">
                <div className="Input_Field">
                  <div className="Label">Last Name</div>
                  <input
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    disabled
                  />
                </div>
                <div className="Input_Field">
                  <div className="Label">Date of Birth</div>
                  <input
                    type="date"
                    name="dob"
                    value={formData.dob}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    style={{ borderColor: inputErrors.dob ? "red" : "#ccc" }}
                  />
                </div>
              </div>

              <div className="Employee_Inputs">
                <div className="Input_Field">
                  <div className="Custom_Select active">
                    <select
                      name="gender"
                      value={formData.gender}
                      onChange={handleInputChange}
                      onBlur={handleInputBlur}
                      style={{
                        borderColor: inputErrors.gender ? "red" : "#ccc",
                      }}
                    >
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                    <img
                      src={Dropdown}
                      alt="Dropdown arrow"
                      className="Dropdown_Arrow"
                    />
                    <div className="Label">Gender</div>
                  </div>
                </div>

                <div className="Input_Field">
                  <div className="Label">Email (Personal)</div>
                  <input
                    type="email"
                    name="personalEmail"
                    value={formData.emailPersonal}
                    onChange={(e) => {
                      setFormData((prev) => ({
                        ...prev,
                        emailPersonal: e.target.value,
                      }));
                      setInputErrors((prevErrors) => ({
                        ...prevErrors,
                        emailPersonal: false,
                      }));
                    }}
                    // onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    style={{
                      borderColor: inputErrors.emailPersonal ? "red" : "#ccc",
                    }}
                  />
                </div>
              </div>

              <div className="Employee_Inputs">
                <div className="Input_Field">
                  <div className="Label">Email (Official)</div>
                  <input
                    type="email"
                    name="officialEmail"
                    value={formData.emailOfficial}
                    // onChange={handleInputChange}
                    onChange={(e) => {
                      setFormData((prev) => ({
                        ...prev,
                        emailOfficial: e.target.value,
                      }));
                      setInputErrors((prevErrors) => ({
                        ...prevErrors,
                        emailOfficial: false,
                      }));
                    }}
                    onBlur={handleInputBlur}
                    style={{
                      borderColor: inputErrors.emailOfficial ? "red" : "#ccc",
                    }}
                  />
                  {/* <h6
                    style={{
                      color: inputErrors.officialEmail ? "red" : "#000",
                    }}
                  >
                    E-mail already registered
                  </h6> */}
                </div>

                <div className="Input_Field">
                  <div className="Label">Mobile No.</div>
                  <input
                    type="number"
                    name="mobileNo"
                    value={formData.mobileNo}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    style={{
                      borderColor: inputErrors.mobileNo ? "red" : "#ccc",
                    }}
                  />
                </div>
              </div>

              <div className="Employee_Inputs">
                <div className="Input_Field">
                  <div className="Custom_Select active">
                    <select
                      name="country"
                      value={formData.country}
                      onChange={(e) => {
                        const selectedCountry = Country.getAllCountries().find(
                          (country) => country.name === e.target.value
                        );
                        // console.log(selectedCountry);
                        setFormData((prevData) => ({
                          ...prevData,
                          country: selectedCountry?.name || "",
                        }));
                        setSelectedCountry(selectedCountry || "");
                        // setInputErrors((prevData) => ({
                        //   ...prevData,
                        //   country: false,
                        // }));
                      }}
                      onBlur={handleInputBlur}
                      style={{
                        borderColor: inputErrors.country ? "red" : "#ccc",
                      }}
                    >
                      <option>select</option>
                      {Country &&
                        Country.getAllCountries().map((i) => (
                          <option value={i.name} key={i.isoCode}>
                            {i.name}
                          </option>
                        ))}
                    </select>
                    <img
                      src={Dropdown}
                      alt="Dropdown arrow"
                      className="Dropdown_Arrow"
                    />
                    <div className="Label">Country</div>
                  </div>
                </div>

                <div className="Input_Field">
                  <div className="Custom_Select active">
                    <select
                      name="state"
                      value={formData.state}
                      // disaled={State.getStatesOfCountry(selectedCountry?.isoCode)?.length===0}
                      onChange={(e) => {
                        // console.log(e.target.value)
                        const selectedState = State.getStatesOfCountry(
                          selectedCountry?.isoCode
                        ).find((state) => state.name === e.target.value);
                        // console.log(selectedState);
                        setFormData((prevData) => ({
                          ...prevData,
                          state: selectedState?.name || "",
                        }));
                        setSelectedState(selectedState);
                        // setInputErrors((prevData) => ({
                        //   ...prevData,
                        //   state: false,
                        // }));
                      }}
                      onBlur={handleInputBlur}
                      style={{
                        borderColor: inputErrors.state ? "red" : "#ccc",
                      }}
                    >
                      {State &&
                        State.getStatesOfCountry(selectedCountry?.isoCode).map(
                          (i) => (
                            <option value={i.name} key={i.isoCode}>
                              {i.name}
                            </option>
                          )
                        )}
                    </select>
                    <img
                      src={Dropdown}
                      alt="Dropdown arrow"
                      className="Dropdown_Arrow"
                    />
                    <div className="Label">State</div>
                  </div>
                </div>
              </div>

              <div className="Employee_Inputs">
                <div className="Input_Field">
                  <div className="Custom_Select active">
                    <select
                      name="city"
                      value={formData.city}
                      onChange={(e) => {
                        // console.log(e)
                        setFormData((prevData) => ({
                          ...prevData,
                          city: e.target.value,
                        }));

                        setInputErrors((prevData) => ({
                          ...prevData,
                          city: false,
                        }));
                      }}
                      onBlur={handleInputBlur}
                      style={{
                        borderColor: inputErrors.city ? "red" : "#ccc",
                      }}
                    >
                      {City &&
                        City.getCitiesOfState(
                          selectedCountry?.isoCode,
                          selectedState?.isoCode
                        )?.map((i) => {
                          return (
                            <option value={i.name} key={i}>
                              {i.name}
                            </option>
                          );
                        })}
                    </select>
                    <img
                      src={Dropdown}
                      alt="Dropdown arrow"
                      className="Dropdown_Arrow"
                    />
                    <div className="Label">City</div>
                  </div>
                </div>
                <div className="Input_Field">
                  <div className="Label">Pincode</div>
                  <input
                    type="number"
                    name="pincode"
                    value={formData.pincode}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    style={{
                      borderColor: inputErrors.pincode ? "red" : "#ccc",
                    }}
                  />
                </div>
              </div>

              <div className="Employee_Inputs">
                <div className="Input_Field">
                  <div className="Label">Address. 1</div>

                  <input
                    type="text"
                    name="addressLine1"
                    value={formData.addressLine1}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    style={{
                      borderColor: inputErrors.addressLine1 ? "red" : "#ccc",
                    }}
                  />
                </div>

                <div className="Input_Field">
                  <div className="Label">Address Line 2</div>
                  <input
                    type="text"
                    name="addressLine2"
                    value={formData.addressLine2}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    style={{
                      borderColor: inputErrors.addressLine2 ? "red" : "#ccc",
                    }}
                  />
                </div>
              </div>

              <div className="Employee_Inputs">
                <div className="Input_Field">
                  <div className="Label">Date of Joining</div>
                  <input
                    type="date"
                    name="joiningDate"
                    value={formData.joiningDate}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    style={{
                      borderColor: inputErrors.joiningDate ? "red" : "#ccc",
                    }}
                  />
                </div>
              </div>
              <div className="Employee_Inputs">
                <div className="Input_Field">
                  <div className="Custom_Select active">
                    <select
                      name="workLocation"
                      value={formData.workLocation}
                      onChange={handleInputChange}
                      onBlur={handleInputBlur}
                      style={{
                        borderColor: inputErrors.workLocation ? "red" : "#ccc",
                      }}
                    >
                      {locations !== undefined &&
                        locations.map((location) => (
                          <option
                            key={location._id}
                            value={`${location.city} - ${location.pincode}`}
                          >
                            {location.city} - {location.pincode}
                          </option>
                        ))}
                    </select>
                    <img
                      src={Dropdown}
                      alt="Dropdown arrow"
                      className="Dropdown_Arrow"
                    />
                    <div className="Label">Work Location</div>
                  </div>
                </div>

                <div className="Input_Field">
                  <div className="Custom_Select active">
                    <select
                      name="department"
                      value={formData.department}
                      onChange={handleInputChange}
                      onBlur={handleInputBlur}
                      style={{
                        borderColor: inputErrors.department ? "red" : "#ccc",
                      }}
                    >
                      {departments?.map((department, index) => (
                        <option key={index} value={department.department}>
                          {department.department}
                        </option>
                      ))}
                    </select>

                    <img
                      src={Dropdown}
                      alt="Dropdown arrow"
                      className="Dropdown_Arrow"
                    />
                    <div className="Label">Department</div>
                  </div>
                </div>
              </div>

              <div className="Employee_Inputs">
                <div className="Input_Field">
                  <div className="Custom_Select active">
                    <select
                      name="designation"
                      value={formData.designation}
                      onChange={handleInputChange}
                      onBlur={handleInputBlur}
                      style={{
                        borderColor: inputErrors.designation ? "red" : "#ccc",
                      }}
                    >
                      {designations?.map((designation, index) => (
                        <option key={index} value={designation.designation}>
                          {designation.designation}
                        </option>
                      ))}
                    </select>
                    <img
                      src={Dropdown}
                      alt="Dropdown arrow"
                      className="Dropdown_Arrow"
                    />
                    <div className="Label">Designation</div>
                  </div>
                </div>

                <div className="Input_Field">
                  <div className="Custom_Select active">
                    <select
                      name="employmentType"
                      value={formData.employmentType}
                      onChange={handleInputChange}
                      onBlur={handleInputBlur}
                      style={{
                        borderColor: inputErrors.employmentType
                          ? "red"
                          : "#ccc",
                      }}
                    >
                      {employmenttypes?.map((employmenttype, index) => (
                        <option
                          key={index}
                          value={employmenttype.employmenttype}
                        >
                          {employmenttype.employmenttype}
                        </option>
                      ))}
                    </select>
                    <img
                      src={Dropdown}
                      alt="Dropdown arrow"
                      className="Dropdown_Arrow"
                    />
                    <div className="Label">Employment Type</div>
                  </div>
                </div>
              </div>

              <div className="Employee_Inputs">
                <div className="Input_Field">
                  <div className="Custom_Select active">
                    <select
                      name="blockOrBuildingNo"
                      value={formData.blockOrBuildingNo}
                      onChange={handleInputChange}
                      onBlur={handleInputBlur}
                      style={{
                        borderColor: inputErrors.blockOrBuildingNo
                          ? "red"
                          : "#ccc",
                      }}
                    >
                      {buildings?.map((building, index) => (
                        <option key={index} value={building.buildingno}>
                          {building.buildingno}
                        </option>
                      ))}
                    </select>
                    <img
                      src={Dropdown}
                      alt="Dropdown arrow"
                      className="Dropdown_Arrow"
                    />
                    <div className="Label">Building No.</div>
                  </div>
                </div>

                <div className="Input_Field">
                  <div className="Custom_Select active">
                    <select
                      name="shift"
                      value={formData.shift}
                      onChange={handleInputChange}
                      onBlur={handleInputBlur}
                      style={{
                        borderColor: inputErrors.shift ? "red" : "#ccc",
                      }}
                    >
                      {shifts?.map((shift, index) => (
                        <option
                          key={index}
                          value={`${shift.fromtiming} - ${shift.totiming}`}
                        >
                          {shift.fromtiming} - {shift.totiming}
                        </option>
                      ))}
                    </select>
                    <img
                      src={Dropdown}
                      alt="Dropdown arrow"
                      className="Dropdown_Arrow"
                    />
                    <div className="Label">Shift</div>
                  </div>
                </div>
              </div>

              <div className="Employee_Inputs">
                <div className="Input_Field">
                  <div className="Custom_Select active">
                    <select
                      name="idProofType"
                      value={formData.idProofType}
                      onChange={handleInputChange}
                      onBlur={handleInputBlur}
                      style={{
                        borderColor: inputErrors.idProofType ? "red" : "#ccc",
                      }}
                    >
                      <option value="Aadhar Card">Aadhar Card</option>
                      <option value="Driving License">Driving License</option>
                      <option value="PAN Card">PAN Card</option>
                    </select>
                    <img
                      src={Dropdown}
                      alt="Dropdown arrow"
                      className="Dropdown_Arrow"
                    />
                    <div className="Label">ID Proof</div>
                  </div>
                </div>

                <div className="Input_Field">
                  <div className="Label">ID Proof No.</div>
                  <input
                    type="text"
                    name="idProofNo"
                    value={formData.idProofNo}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    style={{
                      borderColor: inputErrors.idProofNo ? "red" : "#ccc",
                    }}
                  />
                </div>
              </div>

              <div className="Employee_Inputs">
                <div
                  className="Input_Field file "
                  id="uploadId"
                  style={{
                    borderColor: inputErrors.idProofPhoto ? "red" : "#ccc",
                  }}
                >
                  <div className="Label">ID Proof Photo</div>
                  <input
                    type="file"
                    name="idProofPhoto"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                    onBlur={handleInputBlur}
                  />
                  {selectedFileName && (
                    <div className="FileNameLabel">{selectedFileName}</div>
                  )}
                  {!selectedFileName && (
                    <div className="FileNameLabel">
                      {formData?.idProofPhoto?.name}
                    </div>
                  )}

                  <img
                    className="Upload"
                    src={Upload}
                    alt="Click to upload"
                    style={{ cursor: "pointer" }}
                    onClick={handleImageClick}
                  />

                  <p className="imgType">
                    Upload only .pdf, .png, .jpg, .jpeg files.
                  </p>
                </div>
              </div>

              <div className="Heading_Submit">
                <button
                  style={{
                    border: isRegisterEnabled
                      ? "1px solid #0a3b66"
                      : "1px solid #dddddd",
                  }}
                  onClick={handleSubmit}
                >
                  {loading ? "Updateing..." : "Update"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default EditProfile;
