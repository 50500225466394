import React from 'react'

const NotificationsSkelton = () => {
  return (
    <div className='shimmer_notify'>


{
    [...Array(10)]?.map((sk,i)=>(

        <div>
<div key={i} className='item_notify_top'></div>
<div className="bottom_notifications">
{
    [1,2,3,4]?.map((s,i)=>(
        <div key={i} className='shimmer-item_notify'>

        </div>
    ))
}
</div>
        </div>

    ))
}

{/* {
    [1,2,3,4,5,6,7,8,9,10,12,12]?.map((s,i)=>(
        <div key={i} className='item_notify_top'>

        </div>
    ))
} */}



    </div>
  )
}

export default NotificationsSkelton