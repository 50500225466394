import React from "react";

const NominateHr = ({ open, handleClose, setRightSidebar }) => {
  const assignHr = () => {
    setRightSidebar(true);
    handleClose();
  };
  return (
    <div className="modal-overlay">
      <div className="modal">
        <h2>Nominate HR</h2>
        <p>
          This is a process wherein you'll nominate one your employee as HR.
          This is required for access to Attendance reports and Dashboard.
        </p>
        <button id="nominateHrBtn" onClick={assignHr}>
          <span>OKAY</span>
        </button>
      </div>
    </div>
  );
};

export default NominateHr;
