import React, { useEffect, useState, useRef } from "react";
import { LineChart, lineElementClasses } from "@mui/x-charts/LineChart";
import axios from "axios";
import Layout from "../../components/Layout/AdminLayout/Layout";
import { useCustomer } from "../../context/CustomerProvider";
import TimeFormatter from "../../utils/LiveTimeForDashboard";
import moment from "moment";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { useAttendanceTable } from "../../hooks/useTime/attendanceTable";
import DateRange from "../../model/dateRange/DateRange";
import { UseDatePicker } from "../../hooks/useDatePicker/UseDatePicker";
import AttendanceDateWise from "../../model/attendance/AttendanceDateWise";
import { useModel } from "../../hooks/useModel/useModel";
import { useEmployee } from "../../context/EmpProvider";

const Dashboard = () => {
  const { customer } = useCustomer();
  const { getTimeByCountry } = useAttendanceTable();
const { open, handleClose,handleOpen}=useModel();
  const [userData, setUserData] = useState({
    email: "",
    password: "",
    customerid: "",
    otp: "",
    firstname: "",
    lastname: "",
    accountcreatedon: "",
    address: "",
    businessname: "",
    city: "",
    country: "",
    licenseconsumed: "",
    licenseexprieon: "",
    licenseremaining: "",
    licensevalidity: "",
    phonenumber: "",
    pincode: "",
    state: "",
    totallicense: "",
    mode: "Trial",
  });
  const {  selectedDate,
    setSelectedDate}=useEmployee();
  const [selectFilterType, setFilterType] = useState("");
  useEffect(() => {
    setUserData((prevData) => ({
      ...prevData,
      ...customer?.data,
    }));
  }, [customer]);
  const [companyLogo, setComplanyLogo] = useState("");
  useEffect(() => {
    // console.log("customer?.data?.businesslogo",customer?.data?.businesslogo)
    setComplanyLogo(customer?.data?.businesslogo);
  }, [customer]);
  const [attendanceData, setAttendanceData] = useState({
    todayAttendance: 0,
    yesterdayAttendance: 0,
    totalEmployeeCount: 0,
    todayAttendancePercentage: 0,
    attendanceComparison: "",
    percentageDifference: 0,
  });
  const {
    selectDatePicker,
    setSelectDatePicker,
  
  } = UseDatePicker();
  const [loading, setLoading] = useState(false);
  const [loadingdailyAtt, setLoadingdDailyAtt] = useState(true);
  const [error, setError] = useState(null);
  const [attendanceDataDep, setAttendanceDataDep] = useState([]);
  const [loadingDep, setLoadingDep] = useState(true);
  const [errorDep, setErrorDep] = useState(null);
  const [attendanceDataYear, setAttendanceDataYear] = useState([]);
  const [loadingYear, setLoadingYear] = useState(true);
  const [errorYear, setErrorYear] = useState(null);
  const [attendanceDataByShift, setAttendanceDataByShift] = useState([]);
  const [loadingByShift, setLoadingByShift] = useState(true);
  const [loadingByShiftByChoose, setLoadingByShiftByChoose] = useState(false);
  const [errorByShift, setErrorByShift] = useState(null);
  const [xaxis, setXaxis] = useState([]);
  const [empCount, setEmpCount] = useState([]);
  const [count, setCount] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectShift, setSelectShift] = useState("");
  const timeRef = useRef(null);

  const [reportDownload, setReportDownload] = useState(null);
  const [filterTypes,setFilterTypes]=useState("Today");
  const formatDate = (dateString) => {
    return moment(dateString).format("YYYY-MM-DD");
  };

  const handleSortLog = (type) => {
    if (type) {
      setFilterTypes(type);
      handleOpen();
    }
  };

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };
  const timeToMinutes = (time) => {
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes;
  };

  const minutesToTime = (minutes) => {
    const hours = String(Math.floor(minutes / 60) % 24).padStart(2, "0");
    const mins = String(minutes % 60).padStart(2, "0");
    return `${hours}:${mins}`;
  };

  const getInterval = (shiftDuration) => {
    // Extract start and end times from the shift duration (e.g., "09:10 - 18:10")
    const [startTime, endTime] = shiftDuration.split("-").map((t) => t.trim());

    // Convert start and end times to minutes
    let startMinutes = timeToMinutes(startTime);
    let endMinutes = timeToMinutes(endTime);

    const intervals = [];
    const intervalDuration = 2 * 60; // 2 hours in minutes

    // Handle overnight shift, if end time is earlier than start time
    if (endMinutes <= startMinutes) {
      // Add 24 hours to the end time to account for crossing midnight
      endMinutes += 24 * 60;
    }

    // Push the initial start time
    intervals.push(minutesToTime(startMinutes));

    // Generate interval points till the end time
    while (startMinutes + intervalDuration <= endMinutes) {
      startMinutes += intervalDuration;
      intervals.push(minutesToTime(startMinutes));
    }

    return intervals;
  };

  const getEmployeeCounts = (employees, intervals) => {
    const employeeCounts = new Array(intervals.length).fill(0); // Initialize counts array

    employees.forEach((employee) => {
      const employeeInTime = new Date(
        employee.intimeOutTime
      ).toLocaleTimeString("en-GB", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });

      // console.log(employeeInTime);
      for (let i = 0; i < intervals.length - 1; i++) {
        // console.log(
        //   employeeInTime <= intervals[i + 1],
        //   employeeInTime,
        //   intervals[i + 1]
        // );
        if (
          employeeInTime >= intervals[i] &&
          employeeInTime < intervals[i + 1]
        ) {
          // console.log("working", employeeInTime);
          employeeCounts[i]++;
          break;
        }
      }
    });

    return employeeCounts;
  };

  const handleShiftSelect = (e) => {
    setSelectShift(e.target.value);
    // console.log(e.target.value);

    const inteervalWise = getInterval(e.target.value);
    // console.log(inteervalWise);
    setXaxis(inteervalWise);

    const fetchEmployeeCount = async () => {
      setLoadingByShiftByChoose(true);

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/employees/employee-count-list`,
          {
            params: { shift: e.target.value },
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        );
        if (response.data.success) {
          const employeesData = response?.data?.data?.listOfEmployeeOfThatShift;
          // console.log(employeesData);
          const employeeCounts = getEmployeeCounts(
            employeesData,
            inteervalWise
          );
          // console.log(employeeCounts);
          setEmpCount(employeeCounts);
          setLoadingByShiftByChoose(false);
        }
      } catch (error) {
        setErrorByShift("Error fetching employee count");
        setLoadingByShiftByChoose(false);
      }
    };

    fetchEmployeeCount();
  };

  useEffect(() => {
    const fetchShift = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/employees/shift-list`,
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        );
        if (response.data.success) {
          setLoadingByShift(false);
          setAttendanceDataByShift(response?.data?.data?.shift);
          const employeesData = response?.data?.data?.listOfEmployeeOfThatShift;
          // console.log(employeesData);

          const interval = getInterval(response?.data?.data?.shiftTime);
          // console.log(interval);
          const employeeCounts = getEmployeeCounts(employeesData, interval);
          // console.log(employeeCounts);
          setEmpCount(employeeCounts);
          setXaxis(interval);
          // setXaxis(["9:00", "12:00", "14:00", "16:00", "18:00"]);
        }
      } catch (err) {
        setErrorByShift("Failed to fetch attendance data.");
        setLoadingByShift(false);
      }
    };

    fetchShift();
  }, []);

  useEffect(() => {
    const fetchAttendanceData = async () => {
      try {
        setLoadingdDailyAtt(true); // Set loading state to true before API call

        // Call the API with axios
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/employees/countAttendance`,
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        );

        if (response.data.success) {
          // Assuming the API response contains the data object
          setAttendanceData(response.data.data); // Set the response data to state
          setLoadingdDailyAtt(false);
        }
      } catch (err) {
        setError("Error fetching attendance data"); // Handle errors
      } finally {
        setLoadingdDailyAtt(false); // Turn off loading state after API call
      }
    };

    fetchAttendanceData(); // Call the function when the component mounts
  }, []);

  useEffect(() => {
    // Fetch department attendance data
    const fetchAttendanceData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/employees/department-attendance`,
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        );
        if (response.data?.success) {
          setAttendanceDataDep(response.data?.data);
        }
      } catch (err) {
        setErrorDep(err.message || "Something went wrong");
      } finally {
        setLoadingDep(false);
      }
    };

    fetchAttendanceData();
  }, []);

  useEffect(() => {
    const fetchAttendanceData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/employees/yearly-attendence`,
          {
            params: {
              year: selectedYear || new Date().getFullYear(),
            },

            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        );
        setAttendanceDataYear(response.data.data);
      } catch (err) {
        setErrorYear("Failed to fetch attendance data");
      } finally {
        setLoadingYear(false);
      }
    };

    fetchAttendanceData();
  }, [selectedYear]);

  const listOfYear = () => {
    const accountCreationDate = new Date(userData?.accountcreatedon);
    // const accountCreationDate = new Date();

    // Extract the year from the account creation date
    const accountCreationYear = accountCreationDate.getFullYear();

    // Get the current year
    const currentYear = new Date().getFullYear();

    const yearList = [];
    for (let year = accountCreationYear; year <= currentYear; year++) {
      yearList.push(year);
    }

    // console.log(yearList);
    return yearList;
  };
  const checkEmployeeNumber = (empCount) => {
    for (let i = 0; i < empCount.length; i++) {
      if (empCount[i] !== 0) {
        return false;
      }
    }
    return true;
  };


const handleCustom=(type)=>{
if(type){
  setFilterTypes(type);
  handleOpen();
}
}

  return (
    <Layout>
      <div className="dashboard">
        <div className="one">
          <h3>My Dashboard </h3>
          <p>
            {userData?.country && <TimeFormatter country={userData?.country} />}
          </p>
        </div>
        <div className="commonBorderForAll dashboardAttendance">
          <div className="Dashboard_Data_Container">
            <div className="Dashboard_Details_Tiles">
              <div className="Dashboard_Panel">
                <div className="Best_Employee_of_the_year">
                  <div className="upperest_one">
                    <div className="upper_filter_dashboard">
                      <p>Attendance Report Download</p>
                    </div>
                    <div className="lower_filter_dashboard">
                      {[
                        "Today",
                        "Yesterday",
                        "Last 7days",
                        "Last 30days",
                        "Last 6months",
                        "Custom",
                      ]?.map((b, i) => (
                        <>
                        {
                          b!=="Custom"?
                          
                          <button
                          key={i}
                          onClick={() => {
                            if(b==="Custom"){
                             handleCustom(b)
                            }else{
                              handleSortLog(b);
                            }
                           
                          }}
                        >
                      
                          {b}
                        </button>
                          :
                          selectDatePicker
                          ?
                      <>
                      
                      <button
                          key={i}
                          onClick={() => {
                            setSelectDatePicker(false)
                           
                          }}
                        >
                      
                          Cancel
                        </button>
                           <button
                          key={i}
                          onClick={() => {
                            if(b==="Custom"){
                             handleCustom(b)
                            }else{
                              handleSortLog(b);
                            }
                           
                          }}
                        >
                      
                          Apply
                        </button>
                      </>
                          
                          :

                          <button
                          key={i}
                          onClick={() => {
                            setSelectDatePicker(true);
                          }}
                        >
                      
                          Custom
                        </button>
                        }
                          
                            </>
                      ))}
                                          
                      <div 
                      className="dateRangeSelect"
                      >
                      {selectDatePicker  && <DateRange type="dashboard" />}
                      </div>
                  
                    </div>
                  </div>
                  <div className="upper_second">
                    {loadingdailyAtt ? (
                      <div className="shimmerTodayAtt">
                        <div className="shimmer-itemTodayAtt"></div>
                        <div className="shimmer-itemTodayAtt"></div>
                        <div className="shimmer-itemTodayAtt"></div>
                        <div className="shimmer-itemTodayAtt"></div>
                      </div>
                    ) : (
                      <div className="Current_Date_Attendence">
                        <div className="CurrentDate_Attendance_Heading">
                          <h1>Today’s Attendance</h1>
                        </div>
                        <div className="CurrentDate_Attendance_Heading">
                          <h2>
                            {attendanceData?.todayAttendance}/
                            {attendanceData?.totalEmployeeCount}
                          </h2>
                        </div>

                        <div className="Circle_Graph">
                          <div className="Attendence_CircleGraph">
                            <div
                              className="HalfCircle_Graph"
                              role="progressbar"
                              aria-valuenow={
                                attendanceData?.todayAttendancePercentage
                              }
                              aria-valuemin="0"
                              aria-valuemax="100"
                              style={{
                                "--value":
                                  attendanceData.todayAttendancePercentage,
                              }}
                            >
                              <h1 style={{ fontSize: "2rem" }}>
                                {attendanceData.todayAttendancePercentage}%
                              </h1>
                            </div>
                          </div>
                        </div>

                        <div className="Attendance_Content">
                          <p style={{ padding: "10px" }}>
                            {" "}
                            {attendanceData?.attendanceComparison}{" "}
                          </p>
                        </div>
                      </div>
                    )}
                    <div className="Best_Employee_Data">
                      <div className="Best_Employee_Data_Heading">
                        <div className="Switch_Panel_Heading">
                          <h1>Top 5 Attendance Achievers</h1>
                        </div>
                        <div className="Switch_Panel">
                          <div className="custom-select-container">
                            <label htmlFor="year-select">Select Year:</label>
                            <select
                              id="year-select"
                              value={selectedYear}
                              onChange={handleYearChange}
                              className="custom-select"
                            >
                              {listOfYear().map((year) => (
                                <option key={year} value={year}>
                                  {year}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="Emp_Details_Heading">
                        <div className="Sr_No">
                          <p>#</p>
                        </div>
                        <div className="Emp_Name">
                          <p>Name</p>
                        </div>
                        <div className="ProgressBar">
                          <p>Attendance</p>
                        </div>
                        <div className="Percentage">
                          <p>Percentage</p>
                        </div>
                      </div>
                      {loadingYear ? (
                        <div className="shimmerYear">
                          <div className="shimmer-itemYear"></div>
                          <div className="shimmer-itemYear"></div>
                          <div className="shimmer-itemYear"></div>
                          <div className="shimmer-itemYear"></div>
                        </div>
                      ) : (
                        <div className="Employee_List">
                          {attendanceDataYear.map((employee, index) => (
                            <div key={index} className="Emp_Details_Heading_A">
                              <div className="Sr_No_A">
                                <p>{index + 1}</p>
                              </div>
                              <div className="Emp_Name_A">
                                <p>{`${employee.firstName} ${employee.lastName}`}</p>
                              </div>
                              <div className="ProgressBar_A">
                                <div className={`Progress_Bar_${index + 1}`}>
                                  <progress
                                    id="file"
                                    value={employee.attendancePercentage.toFixed(
                                      0
                                    )}
                                    max="100"
                                  >
                                    {employee.attendancePercentage.toFixed(0)}
                                  </progress>
                                </div>
                              </div>
                              <div className="Percentage_A">
                                <div
                                  className={`Percentage_Container_${
                                    index + 1
                                  }`}
                                >
                                  <p>
                                    {employee.attendancePercentage.toFixed(0)}%
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="lower_One">
                    <div className="Dashboard_lowerGraph">
                      {loadingByShift || loadingByShiftByChoose ? (
                        <div className="shimmerGraph">
                          <div className="shimmer-itemGraph"></div>
                          <div className="shimmer-itemGraph"></div>
                          <div className="shimmer-itemGraph"></div>
                          <div className="shimmer-itemGraph"></div>
                        </div>
                      ) : (
                        <div className="Punctuality_index_Graph">
                          <div className="Punctuality_index_Graph_Heading">
                            <div className="Switch_Panel_Heading">
                              <h1>Punctuality index Graph</h1>
                            </div>
                            <div className="custom-select-container">
                              <label htmlFor="shift-select">
                                Select Shift:
                              </label>
                              <select
                                id="shift-select"
                                value={selectShift}
                                onChange={(e) => handleShiftSelect(e)}
                                className="custom-select"
                              >
                                {attendanceDataByShift.map((shift) => (
                                  <option key={shift} value={shift}>
                                    {shift}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          {xaxis.length === 0 || xaxis.length <= 1 ? (
                            <div className="notFoundCount">
                              <h3>
                                Shift duration must be a minimum of 2 hours.
                              </h3>
                            </div>
                          ) : checkEmployeeNumber(empCount) ? (
                            <div className="notFoundCount">
                              <h3>
                                No employees are available for this shift.
                              </h3>
                            </div>
                          ) : (
                            <div className="graphShowing">
                              {!loadingByShift && (
                                <LineChart
                                  series={[
                                    {
                                      data: empCount,
                                      label: "Attendance",
                                      area: true,
                                      showMark: false,
                                      color: "#104372",
                                    },
                                  ]}
                                  xAxis={[{ scaleType: "point", data: xaxis }]} // X-axis: shift timings
                                  sx={{
                                    [`& .${lineElementClasses.root}`]: {
                                      display: "none",
                                    },
                                  }}
                                />
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="Department_Specific_Attendance" id="DesktopAtt">
                  <div className="Historical_Attendance_Heading">
                    <h1>Department Specific Attendance</h1>
                  </div>
                  {loadingDep ? (
                    <div className="shimmer">
                      {[1, 2, 3, 4, 5, 6, 7, 8]?.map((s, i) => (
                        <div className="shimmer-item"></div>
                      ))}
                    </div>
                  ) : (
                    <div className="Department_Specific_Data">
                      {attendanceDataDep.map((data, index) => (
                        <div key={index} className="Department_Data_Tiles">
                          <div className="NumbersOfEmployees">
                            <h1>
                              {data?.todayAttendance}/{data?.totalEmployeeCount}
                            </h1>
                          </div>
                          <div className="All_Emp_Data">
                            <div className="Table_Container">
                              <div className="Table_Heading">
                                <p>On Time</p>
                              </div>
                              <div className="Table_1">
                                <p>:</p>
                              </div>
                              <div className="Table_2">
                                <p>{data?.onTimeCount}</p>
                              </div>
                            </div>
                            <div className="Table_Container">
                              <div className="Table_Heading">
                                <p>Late</p>
                              </div>
                              <div className="Table_1">
                                <p>:</p>
                              </div>
                              <div className="Table_2">
                                <p>{data?.lateCount}</p>
                              </div>
                            </div>
                          </div>
                          <div className="Department_Heading">
                            <h1>{data?.departmentName}</h1>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              
            </div>
          </div>
        </div>
      </div>
      <AttendanceDateWise
      open={open}
      handleClose={handleClose}
      
      filterType={filterTypes}
      />
    </Layout>
  );
};

export default Dashboard;
